import { useEffect, useState } from 'react';
import CategoryIcon from '../../assets/icons/CategoryIcon.png';
import Accordion from "../accordion/accordion-container/Accordion";
import AccordionItem from "../accordion/accordion-item/AccordionItem";
import { getBrands, getCategories } from '../../api/lib/product';
import { Category } from '../../interfaces/category.interfaces';
import { useSearchParams } from 'react-router-dom';
import { Brand } from '../../interfaces/brand.interfaces';

const FilterSidebar = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]); 
  const [searchParams, setSearchParams] = useSearchParams([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories({ page: 1, limit: 8 });
        const categoryList = response.data.data.list;
        setCategories(categoryList);
      } catch (error) {
        console.error("Error Fetching Categories", error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await getBrands({ page: 1, limit: 10 });
        const brandList = response.data.data.list;
        setBrands(brandList);
      } catch (error) {
        console.error("Error Fetching Brands", error);
      }
    };

    fetchCategories();
    fetchBrands();
  }, []);

  const handleFilterClick = (id: string, type: 'category' | 'brand') => {
    // Create a new URLSearchParams instance to ensure fresh updates
    const updatedSearchParams = new URLSearchParams(searchParams);
  
    if (type === 'category') {
      // Set the selected category ID as the sole value for "q"
      updatedSearchParams.set("q", id);
    } else if (type === 'brand') {
      // For brands, allow multiple selections
      const existingBrands = updatedSearchParams.get("brand")?.split(",") || [];
      const updatedBrands = existingBrands.includes(id)
        ? existingBrands.filter(brandId => brandId !== id)
        : [...existingBrands, id];
        
      if (updatedBrands.length > 0) {
        updatedSearchParams.set("brand", updatedBrands.join(","));
      } else {
        updatedSearchParams.delete("brand");
      }
    }
  
    setSearchParams(updatedSearchParams);
  };
  

  return (
    <>
      <div className="flex flex-col py-2 px-3">
        <span className="text-lg font-semibold">Category</span>
      </div>
      <div className="border-2 flex flex-col w-60 bg-white">
        <div className="flex flex-row items-center gap-4 py-2 px-3">
          <img className='w-[29px] h-[29px]' src={CategoryIcon} alt="Category Icon" />
          <span className="text-md font-semibold">All</span>
        </div>

        {categories.map((category) => (
          <Accordion title={category.name} key={category._id}>
            {category?.subcategories?.map((subCategory) => (
              <div
                key={subCategory._id}
                onClick={() => handleFilterClick(subCategory._id, 'category')}
                role="button"
                tabIndex={0}
                className="cursor-pointer"
              >
                <AccordionItem label={subCategory.name} />
              </div>
            ))}
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default FilterSidebar;

import { Grid } from "@mui/material";
import OrderCard from "../../components/order-card/OrderCard";

const OrderPage = () => {
  return (
    <>
      <Grid item xs>
        <div className="" >
          {/* <div className="-ml-2 inline-flex items-end text-primary cursor-pointer hover:text-red-800 ">
            <ChevronLeftOutlinedIcon fontSize="medium" />
            <span >
              Back to my account
            </span>
          </div> */}
          <div className="mb-4">
            <span className="font-bold text-2xl inline-block border-b-[3px] border-primary pb-1">My Orders:</span>
          </div>
          <div>
            <div className="flex flex-col gap-4">
              <OrderCard />
              <OrderCard />
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default OrderPage;

import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useLayoutEffect, useEffect } from "react";
import { useCart } from "../../../context/CartContext";
import { getRequiredProductFromCatalog } from "../../../api/lib/product";
import ProductPageShimmer from "../../../components/shimmer-ui-components/ProductPageShimmer";
import ImageSlider from "./ImageSlider";
import { Product } from "../../../interfaces/product.interfaces";
import { Icon } from "@iconify/react";
import WholeSaleSolution from "../../home/static/WholeSaleSolution";

const ProductPage = () => {
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(false);
  const { sku } = useParams();
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await getRequiredProductFromCatalog({ sku: sku });
        setProduct(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(`Error in fetching product?`, error);
      }
    };
    fetchProduct();
  }, [sku]);

  const [quantity, setQuantity] = useState(1); // Initial quantity
  const { addToCart } = useCart();

  const handleIncrement = (e) => {
    e.stopPropagation();
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = (e) => {
    e.stopPropagation();
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return loading ? <ProductPageShimmer /> : <div></div>;
  }

  const handleCartButton = async () => {
    addToCart({
      catalogId: product?._id,
      quantity: 1,
      price: product?.price?.selling,
      reqType: 1,
    });
  };

  return (
    <>
      <Container maxWidth={"lg"} className="py-10">
        {loading ? (
          <ProductPageShimmer />
        ) : (
          <Grid container className="justify-between">
            <Grid className="flex" item xs={6}>
              <Grid container display={"flex"}>
                <Grid item xs={12} className="flex justify-center">
                  <ImageSlider imagesUrls={product?.mediaUrls} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4.5}>
              <div id="info-container" className="flex flex-col gap-3">
                <div id="brand-name-and-size">
                  {/* <div className="text-3xl font-bold text-gray-dark">
                    {product?.brands[0]?.name}
                  </div> */}
                  <div className="md:text-3xl text-2xl font-semibold text-black capitalize">
                    {product?.name}
                  </div>
                  {/* <span className="inline-flex pb-4">Peti (850 gm)</span> */}
                </div>
                <div id="price-details" className="flex flex-col">
                  <div className="flex items-center">
                    <div className="md:text-3xl text-2xl font-semibold mr-3">
                      ₹{product?.price?.selling}
                      {/* <span className="packageCount ml-2 font-semibold color-textgrey text-lg">
                        (1 X 12Pcs)
                      </span> */}
                    </div>
                    {/* <div className=" text-xl font-bold text-gray-dark line-through decoration-red decoration-[1.5px]">
                      ₹{product?.price?.mrp}
                    </div>
                    <div className="text-sm ml-3 text-secondary uppercase">
                      ({product?.percentageOff}% off)
                    </div> */}
                  </div>
                </div>
                <div
                  id="quantity-and-add-to-cart-container"
                  className="flex items-center gap-4"
                >
                  <div
                    id="quantity-counter"
                    className="flex items-center bg-white countBtn"
                  >
                    <button
                      type="button"
                      id="decrement-button"
                      onClick={(e) => handleDecrement(e)}
                      className="flex items-center justify-center bg-white"
                    >
                      <span className="text-black">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="4"
                          fill="none"
                          viewBox="0 0 20 4"
                        >
                          <path
                            fill="#343539"
                            d="M17.737 3.886H2.444c-1.008 0-1.821-.728-1.821-1.63 0-.902.813-1.63 1.82-1.63h15.294c1.008 0 1.82.728 1.82 1.63 0 .902-.812 1.63-1.82 1.63z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                    <div className="flex items-center justify-center text-black text-md font-semibold px-2">
                      {quantity}
                    </div>
                    <button
                      type="button"
                      id="increment-button"
                      onClick={(e) => handleIncrement(e)}
                      className="flex items-center justify-center bg-white"
                    >
                      <span className="text-black">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          fill="none"
                          viewBox="0 0 19 19"
                        >
                          <path
                            fill="#343539"
                            d="M17.338 7.723H11.35V1.971c0-.86-.727-1.559-1.623-1.559s-1.623.698-1.623 1.56v5.751H2.116c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56v-5.752h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div id="add-to-cart-button" className="addButton">
                    <button className="flex items-center add-btn">
                      Add{" "}
                      <svg
                        className="ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        fill="none"
                        viewBox="0 0 19 18"
                      >
                        <path
                          fill="#fff"
                          d="M17.29 7.311h-5.987V1.56C11.303.7 10.576 0 9.68 0S8.057.698 8.057 1.56V7.31H2.069c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56V10.43h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                        ></path>
                      </svg>
                      {/* <span className=' z-10 text-xl'>+</span>
                      <div className='absolute inset-0 bg-green-light opacity-40 z-0'></div> */}
                    </button>
                  </div>
                </div>
                <div
                  id="product-details-container"
                  className="mt-4 flex flex-col"
                >
                  <span className="text-xl md:text-2xl font-semibold">
                    Product Details
                  </span>
                  <span className="flex items-center font-medium color-text-lightgray">
                    {/* <Icon className="text-4xl" icon="mdi:dot" /> */}
                    {product.description}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* <div className="wrap-similaritems py-6">
        <div className="custom-container">
          <div className='md:text-3xl text-2xl font-semibold pb-4'>Similar Items</div>
          Add similar product card here...
        </div>
      </div> */}

      <WholeSaleSolution />
    </>
  );
};

export default ProductPage;

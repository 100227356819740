import { Link } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import SubNavbar from "./Navbar/SubNavbar";


const Header = () =>{
    return <header className="header mb-6">
    <Navbar/>
    {/* <SubNavbar/> */}
  </header>;
}

export default Header
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import PaypalCheckout from './PaypalCheckout'; // Ensure this is the correct path

const PaymentGateway = () => {
    const [paypalCheckout, setPaypalCheckout] = useState(false);

    const razorpayCheckout = async (e) => {
        e.preventDefault(); // Prevent default action
        const amount = '50000';
        const currency = "INR";
        const receiptId = "qwsaq1";
        const response = await fetch('http://localhost:5000/order', {
            method: "POST",
            body: JSON.stringify({ amount, currency, receipt: receiptId }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        const order = await response.json();
        console.log(order);
        var options = {
            "key": "rzp_test_5jdAr4E5tMj5pn",
            amount,
            currency,
            "name": "Acme Corp",
            "description": "Test Transaction",
            "image": "https://example.com/your_logo",
            "order_id": order.id,
            "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
            "prefill": {
                "name": "Gaurav Kumar",
                "email": "gaurav.kumar@example.com",
                "contact": "9000090000"
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#e01b24"
            }
        };
        var rzp1 = new (window as any).Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    };

    const handlePaypalCheckout = () => {
        setPaypalCheckout(true);
    };

    return (
        <>
            {!paypalCheckout ? (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[350px] bg-white shadow-xl rounded-md">
                    <div className="pb-3 flex flex-col">
                        <span className='text-2xl font-bold tracking-wide flex justify-center mt-2'>Choose Payment Method</span>
                        <div className="mt-3 flex justify-center items-center">
                            <div className="flex flex-col justify-center items-center mx-2 px-1 cursor-pointer transform transition duration-200 
                                    hover:scale-105" onClick={handlePaypalCheckout}>
                                <Icon icon="logos:paypal" width="48" height="48" />
                                <span className="text-md font-bold">Paypal</span>
                            </div>
                            <div className="flex flex-col justify-center items-center mx-2 px-1 cursor-pointer transform transition duration-200 
                                    hover:scale-105" onClick={razorpayCheckout}>
                                <Icon icon="simple-icons:razorpay" width="48" height="48" style={{ color: '#1b41f3' }} />
                                <span className="text-md font-bold">Razorpay</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <PaypalCheckout />
            )}
        </>
    );
};

export default PaymentGateway;

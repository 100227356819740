import { Icon } from '@iconify/react';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getBrands } from '../../api/lib/product';
import SearchBar from '../search-bar/SearchBar';
import { Brand } from '../../interfaces/brand.interfaces';

const SortingBar = () => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [visibleBrands, setVisibleBrands] = useState(false);
  const [visibleSortingOptions, setVisibleSortingOptions] = useState(false);
  const [brands, setBrands] = useState<Brand[]>([]); 
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await getBrands({ page: 1, limit: 10 });
        const brandList = response.data.data.list;
        setBrands(brandList);
      } catch (error) {
        console.error("Error Fetching Brands", error);
      }
    };
    fetchBrands();
  }, []);

  const toggleBrandFilter = (brandId) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    const existingBrands = updatedSearchParams.get("brand")?.split(",") || [];
    const updatedBrands = existingBrands.includes(brandId)
      ? existingBrands.filter(id => id !== brandId)
      : [...existingBrands, brandId];
    
    if (updatedBrands.length > 0) {
      updatedSearchParams.set("brand", updatedBrands.join(","));
    } else {
      updatedSearchParams.delete("brand");
    }
    
    setSearchParams(updatedSearchParams);
  };

  const sortingOptions = [
    'High Price to Low Price',
    'Low Price to High Price',
    'New Launch',
  ];

  return (
    <div className="flex w-[90%] items-center justify-between gap-4 p-1">
      <button className='flex items-center font-semibold border-2 px-4 py-1' onClick={() => setVisibleBrands(true)}>
        Brand <Icon className='text-2xl' icon="iconamoon:arrow-down-2" />
      </button>
      <button className='flex items-center font-semibold border-2 px-4 py-1' onClick={() => setVisibleSortingOptions(true)}>
        Sort By <Icon className='text-2xl' icon="iconamoon:arrow-down-2" />
      </button>
      
      <Dialog draggable={false} header="Search Brands" visible={visibleBrands} onHide={() => setVisibleBrands(false)}>
        <div>
          <div className='flex justify-center'>
            <SearchBar />
          </div>
          {brands.map((brand) => (
            <div key={brand._id} className="flex justify-between items-center cursor-pointer px-3 py-2">
              <label className="ms-2 text-sm font-medium text-gray-dark cursor-pointer">
                {brand.name}
              </label>
              <input 
                type="checkbox" 
                checked={searchParams.get("brand")?.split(",").includes(brand._id) || false} 
                onChange={() => toggleBrandFilter(brand._id)} 
                className="text-primary border-gray-light rounded" 
              />
            </div>
          ))}
        </div>
      </Dialog>
      
      <Dialog  draggable={false} header="Sorting Options" className='w-[40%]' visible={visibleSortingOptions} onHide={() => setVisibleSortingOptions(false)}>
        <div>
          {sortingOptions.map((option, index) => (
            <div key={index} className="flex justify-between items-center cursor-pointer px-3 py-2">
              <label className="ms-2 text-sm font-medium text-gray-dark cursor-pointer">
                {option}
              </label>
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default SortingBar;

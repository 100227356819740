import React, { useEffect, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import { auth } from "../../firebase/firebase";
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { ENUM } from '../../constant/enum';
import { CLIENT_ROUTES } from '../../constant/client_route';
import { userSignup } from '../../api/lib/user';

const ValidateOTPPopup = ({setSteps,setVisibleLogin, confirmationResult,setFirebaseMetadata}) => {
    const [otp, setOtp] = useState('');
    const [resendActive, setResendActive] = useState(false);
    const [minutes, setMinutes] = useState(ENUM.TIMER.MINUTES)
    const [seconds, setSeconds] = useState(ENUM.TIMER.SECONDS)
    

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    // const { confirmationResult } = location.state || {};
    const { phoneNumber, phoneOrEmailEntered, setPhoneOrEmailEntered, setSignUpByPhone, login } = useAuth();

    // useEffect(() => {
    //     if (!phoneOrEmailEntered) {
    //         navigate(CLIENT_ROUTES.AUTH.LOGIN)
    //     }

    //     if (!confirmationResult?.verificationId) {
    //         navigate(CLIENT_ROUTES.AUTH.LOGIN);
    //     }
    // }, [confirmationResult, navigate, phoneOrEmailEntered]);

    useEffect(() => {
        let interval;
        if (minutes > 0 || seconds > 0) {
            interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else if (minutes > 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    clearInterval(interval);
                    setResendActive(true);
                }
            }, 1000);
        } else {
            setResendActive(true);
        }
        return () => clearInterval(interval);
    }, [minutes, seconds])
    

    const verifyOtp = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const otp = inputRefs.current.map(input => input?.value).join('');
            setOtp(otp);
            console.log(otp,confirmationResult.verificationId)
            const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
            const userCredential = await signInWithCredential(auth, credential);
            setPhoneOrEmailEntered(false);
            const userMetaData = {
                uid: userCredential.user.uid,
                phoneNumber: userCredential.user.phoneNumber,
                metaData: userCredential.user.metadata,
            };
            userSignup({
                phone: phoneNumber, signupType: ENUM.SIGNUP_TYPE.PHONE_SIGNUP, authReqType: ENUM.AUTH_REQUEST_TYPE.CHECK_PHONE,
            }).then((response) => {
                const isExist = response.data.data.isExist;
                if (!isExist) {
                    setSignUpByPhone(true);
                    setFirebaseMetadata(userMetaData); 
                    setSteps(3); 
                } else {
                    const userData = response.data.data;
                    login(userData);
                    setSteps(1);
                    setVisibleLogin(false); 
                }
            }).catch((error) => {
                setSteps(1);
                console.error(`Error Logging In With Phone`, error)
            })
        } catch (error) {
            console.error('Error Verifying OTP', error);
            setSteps(1); 
        }
    };

    const handleResend = async () => {
        try {
            setMinutes(ENUM.TIMER.MINUTES);
            setSeconds(ENUM.TIMER.SECONDS);
            setResendActive(false);
        } catch (error) {
            console.error('Error resending OTP', error);
        }
    }

    useEffect(() => {

        const inputs = inputRefs.current.filter((input): input is HTMLInputElement => input !== null);

        const handleKeyDown = (e: KeyboardEvent) => {
            if (
                !/^[0-9]{1}$/.test(e.key)
                && e.key !== 'Backspace'
                && e.key !== 'Delete'
                && e.key !== 'Tab'
                && !e.metaKey
            ) {
                e.preventDefault();
            }

            if (e.key === 'Delete' || e.key === 'Backspace') {
                const target = e.target as HTMLInputElement;
                const index = inputs.indexOf(target);
                if (index > 0) {
                    if (target.value === '') {
                        inputs[index - 1].focus();
                    }
                    else {
                        target.value = '';
                    }
                }
            }
        };

        const handleInput = (e: Event) => {
            const target = e.target as HTMLInputElement;
            const index = inputs.indexOf(target);
            if (target.value) {
                if (index < inputs.length - 1) {
                    inputs[index + 1].focus();
                } else {
                    const submitButton = document.querySelector('button[type=submit]') as HTMLButtonElement;
                    submitButton?.focus();
                }
            }
        };

        const handleFocus = (e: FocusEvent) => {
            (e.target as HTMLInputElement).select();
        };

        inputs.forEach(input => {
            input.addEventListener('keydown', handleKeyDown);
            input.addEventListener('input', handleInput);
            input.addEventListener('focus', handleFocus);
        });

        return () => {
            inputs.forEach(input => {
                input.removeEventListener('keydown', handleKeyDown);
                input.removeEventListener('input', handleInput);
                input.removeEventListener('focus', handleFocus);
            });
        };
    }, []);


    return (
        <>
            <div className='wrapOtp-screen'>
                <form className='otpWrapper'>
                    <div className='px-3 py-2'>
                        <div className='flex flex-col'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="151"
                                height="168"
                                fill="none"
                                viewBox="0 0 151 168"
                            >
                                <circle cx="72.905" cy="72.905" r="72.905" fill="#FAEAEA"></circle>
                                <path
                                fill="#666363"
                                d="M29.359 63.896c-.65 0-1.178.474-1.178 1.061l-.019 6.16c0 .587.525 1.068 1.172 1.068l.025-8.292zM29.279 74.607c-.65 0-1.178.475-1.178 1.062l-.019 6.163c0 .587.525 1.068 1.172 1.068zM110.255 63.893c.65 0 1.175.48 1.172 1.067l-.054 18.435c0 .588-.527 1.065-1.178 1.062z"
                                ></path>
                                <path
                                fill="#333"
                                d="M109.675 46.522c.032-9.055.047-13.581-2.359-16.76a12.3 12.3 0 0 0-2.789-2.676c-3.317-2.312-8.06-2.325-17.543-2.344l-33.938-.075c-9.486-.022-14.226-.031-17.559 2.268a12.4 12.4 0 0 0-2.808 2.664c-2.425 3.169-2.44 7.695-2.472 16.75l-.34 99.069c-.03 9.055-.046 13.582 2.36 16.76a12.4 12.4 0 0 0 2.79 2.677c3.316 2.311 8.059 2.324 17.542 2.343l33.939.075c9.486.022 14.225.032 17.558-2.268a12.4 12.4 0 0 0 2.808-2.663c2.425-3.17 2.441-7.696 2.472-16.751z"
                                ></path>
                                <path
                                fill="url(#paint0_linear_317_14389)"
                                fillRule="evenodd"
                                d="m52.501 25.49 34.637.076c4.868.01 8.418.018 11.172.308 2.733.285 4.536.832 5.975 1.821.983.679 1.85 1.499 2.563 2.428 1.042 1.36 1.611 3.063 1.897 5.642.289 2.6.279 5.946.264 10.535l-.343 99.844c-.015 4.589-.028 7.934-.336 10.535-.305 2.575-.886 4.278-1.938 5.632a11.3 11.3 0 0 1-2.579 2.418c-1.445.983-3.251 1.52-5.987 1.794-2.76.276-6.307.27-11.175.257l-34.636-.075c-4.869-.01-8.418-.019-11.173-.308-2.733-.286-4.536-.832-5.974-1.822a11.4 11.4 0 0 1-2.563-2.428c-1.043-1.36-1.612-3.062-1.9-5.641-.29-2.601-.28-5.946-.265-10.535l.35-99.844c.015-4.59.027-7.934.335-10.535.305-2.576.886-4.278 1.938-5.632.72-.927 1.59-1.743 2.579-2.419 1.442-.983 3.25-1.52 5.987-1.793 2.76-.277 6.307-.27 11.176-.258zm55.393 3.92c2.453 3.204 2.437 7.768 2.406 16.893l-.34 99.844c-.031 9.124-.047 13.688-2.522 16.88a12.6 12.6 0 0 1-2.865 2.685c-3.401 2.315-8.242 2.306-17.92 2.284l-34.635-.076c-9.681-.022-14.521-.031-17.904-2.362a12.5 12.5 0 0 1-2.846-2.698c-2.453-3.204-2.437-7.768-2.406-16.892l.336-99.844c.032-9.125.047-13.689 2.522-16.88a12.6 12.6 0 0 1 2.865-2.686c3.402-2.318 8.242-2.308 17.923-2.286l34.636.075c9.68.022 14.521.031 17.904 2.362a12.6 12.6 0 0 1 2.846 2.698z"
                                clipRule="evenodd"
                                ></path>
                                <path
                                fill="url(#paint1_linear_317_14389)"
                                d="M106.122 45.684c.025-7.262.038-10.894-1.995-13.444a10.3 10.3 0 0 0-2.355-2.149c-2.802-1.856-6.807-1.862-14.814-1.881l-34.29-.075c-8.01-.016-12.015-.026-14.826 1.818a10.4 10.4 0 0 0-2.371 2.136c-2.048 2.541-2.06 6.172-2.086 13.434l-.349 100.733c-.025 7.262-.037 10.893 1.995 13.444a10.3 10.3 0 0 0 2.356 2.148c2.801 1.857 6.806 1.863 14.813 1.882l34.29.072c8.01.019 12.015.025 14.829-1.819a10.4 10.4 0 0 0 2.372-2.135c2.048-2.542 2.06-6.173 2.085-13.435l.349-100.733z"
                                ></path>
                                <path
                                fill="#333"
                                d="m61.956 31.59 7.608-.019c1.583-.003 2.87 1.156 2.874 2.592.003 1.435-1.276 2.604-2.859 2.607l-7.607.019c-1.583.003-2.871-1.156-2.874-2.592-.004-1.435 1.275-2.604 2.858-2.607M77.159 36.673c1.473 0 2.667-1.127 2.667-2.516s-1.194-2.516-2.667-2.516-2.667 1.126-2.667 2.516 1.194 2.516 2.667 2.516"
                                ></path>
                                <path
                                fill="#F6F5FF"
                                d="M44.482 71.982a4.4 4.4 0 0 1 4.398-4.397h84.55a4.4 4.4 0 0 1 4.398 4.397v28.084a4.4 4.4 0 0 1-4.398 4.398H69.036l-9.22 14.021-1.343-14.021H48.88a4.4 4.4 0 0 1-4.398-4.398z"
                                ></path>
                                <path
                                fill="#69699E"
                                d="m59.824 89.846.113-3.141-2.655 1.683-.684-1.2 2.798-1.457-2.798-1.457.684-1.2 2.655 1.683-.113-3.14h1.369l-.113 3.14 2.654-1.683.685 1.2-2.799 1.457 2.799 1.457-.685 1.2-2.654-1.683.113 3.14zM80.178 89.846l.113-3.141-2.657 1.683-.685-1.2 2.799-1.457-2.799-1.457.685-1.2 2.657 1.683-.113-3.14h1.37l-.113 3.14 2.657-1.683.685 1.2-2.799 1.457 2.799 1.457-.685 1.2-2.657-1.683.113 3.14zM100.536 89.846l.113-3.141-2.658 1.683-.684-1.2 2.798-1.457-2.798-1.457.684-1.2 2.658 1.683-.113-3.14h1.369l-.113 3.14 2.657-1.683.685 1.2-2.799 1.457 2.799 1.457-.685 1.2-2.657-1.683.113 3.14zM120.888 89.846l.113-3.141-2.654 1.683-.685-1.2 2.799-1.457-2.799-1.457.685-1.2 2.654 1.683-.113-3.14h1.369l-.113 3.14 2.655-1.683.684 1.2-2.798 1.457 2.798 1.457-.684 1.2-2.655-1.683.113 3.14z"
                                ></path>
                                <path
                                fill="#9BD3AA"
                                d="M150.176 71.434a12.413 12.413 0 0 1-14.828 12.172 12.404 12.404 0 0 1-9.04-16.918 12.395 12.395 0 0 1 11.464-7.658 12.407 12.407 0 0 1 12.408 12.407z"
                                ></path>
                                <path
                                fill="#fff"
                                d="m137.929 78.138-.11-.05c-.053-.026-5.424-2.592-5.424-10.247v-.2l.197-.045c1.86-.408 2.959-.986 3.76-1.407.622-.326 1.074-.562 1.571-.562s.948.236 1.57.562c.801.421 1.901.999 3.76 1.407l.198.044v.201c0 7.4-5.362 10.215-5.415 10.243l-.11.054z"
                                ></path>
                                <path
                                fill="#9BD3AA"
                                d="m137.505 73.718-1.898-1.819a.63.63 0 0 1 .87-.907l1.028.986 2.186-2.095a.627.627 0 0 1 .889.019.63.63 0 0 1-.019.888l-3.056 2.931z"
                                ></path>
                                <defs>
                                <linearGradient
                                    id="paint0_linear_317_14389"
                                    x1="28.947"
                                    x2="105.616"
                                    y1="19.551"
                                    y2="164.054"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#F2F2F2"></stop>
                                    <stop offset="0.48" stopColor="#F7F7F7"></stop>
                                    <stop offset="1" stopColor="#D2D2D2"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="paint1_linear_317_14389"
                                    x1="69.579"
                                    x2="69.579"
                                    y1="159.31"
                                    y2="26.844"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#fff"></stop>
                                    <stop offset="1" stopColor="#fff"></stop>
                                </linearGradient>
                                </defs>
                            </svg>
                            <div className='md:text-3xl text-xl font-semibold color-lightblack mt-4'>Enter Verification Code</div>
                            <p className='text-sm font-medium color-lightblack'>6 Digit OTP has been sent to 
                                <span>{phoneNumber}</span>
                            </p>
                            {/* <div className='flex flex-col items-center justify-center text-center mt-4'>
                                <div className="font-semibold text-2xl">
                                    <p>Verify OTP</p>
                                </div>
                                <div className="flex text-sm font-medium text-gray">
                                    <p>Enter the 6-digit OTP that was sent to your phone number.
                                        <span className='ml-1 font-semibold text-gray-dark'><br />{phoneNumber}</span>
                                    </p>
                                </div>
                            </div> */}

                            <div className='flex items-center gap-3 mb-4'>
                                {[...Array(6)].map((_, index) => (
                                    <div className='h-10 w-10' key={index}>
                                        <input
                                            className="otp-input border-color-[#DEDEE3] w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-md border  text-gray-dark font-semibold focus:bg-gray-light focus:ring-1 ring-blue-dark"
                                            type="text"
                                            maxLength={1}
                                            pattern="\d*"
                                            ref={element => inputRefs.current[index] = element}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='mt-2 flex items-center'>
                                {resendActive ? (
                                    <div className='text-sm'>
                                        <span className='time block'>00:30</span>
                                        <p className='mb-0'>Did’nt receive the code? <button type="button" className='text-[#949399]' onClick={handleResend}> Resend now</button></p>
                                    </div>
                                ) : (
                                    <p className=''>RESEND OTP IN {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}s</p>
                                )}
                            </div>
                            <div className='mt-3 border-t border-[#DDDDE6] pt-4'>

                                <div className="flex items-center pb-2">
                                    <input id="link-checkbox" type="checkbox" value="" className="h-4 h-6 w-6 border-color-[#CA2F2E]"/>
                                    <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium color-lightblack">I agree with the <a href="#" className="color-textred hover:underline">Terms and 
                                        Conditions</a>.</label>
                                </div>

                                <button type="submit" className="mt-3 mb-2 py-2 w-full flex justify-center items-center text-md h-[60px] text-white bg-[#3C881F] rounded-xl tracking-wider font-bold" onClick={verifyOtp}>
                                    Verify 
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};

export default ValidateOTPPopup;

import { SERVER_ENDPONTS } from "../../constant/server_endpoints";
import axiosClient from "../axiosClient";

export function userChangePassword(payload = {}) {
    return axiosClient.put(SERVER_ENDPONTS.AUTH.CHANGE_PASSWORD, payload)
}

export function userSignup(payload = {}) {
    return axiosClient.post(SERVER_ENDPONTS.AUTH.SIGN_UP, payload)
}

export function userLogin(payload = {}) {
    return axiosClient.post(SERVER_ENDPONTS.AUTH.LOGIN, payload)
}

export function userForgotPassword(payload = {}) {
    return axiosClient.post(SERVER_ENDPONTS.AUTH.FORGOT_PASSWORD, payload)
}

export function verifyUserEmail(payload = {}) {
    return axiosClient.post(SERVER_ENDPONTS.AUTH.VERIFY_EMAIL, payload)
}

export function userResendOtp(payload = {}) {
    return axiosClient.post(SERVER_ENDPONTS.AUTH.RESEND_OTP, payload)
}

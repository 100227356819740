import { SERVER_ENDPONTS } from "../../constant/server_endpoints";
import axiosClient from "../axiosClient";

export function addOrRemoveFromWishlist(payload = {}) {
    return axiosClient.post(SERVER_ENDPONTS.CATALOG.ADD_REMOVE_FROM_WISHLIST,  payload )
}

export function getAllWishlist(params={}){
    return axiosClient.get(SERVER_ENDPONTS.CATALOG.ALL_WISHLIST,{params:params}); 
}

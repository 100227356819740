import { useEffect, useState } from "react";
import { Grid, Paper, Button } from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { CLIENT_ROUTES } from "../../../constant/client_route";

const tabs = ['Profile', 'Orders', 'Addresses', 'Gift Vouchers', 'FAQs'];

const TabsMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser, logout } = useAuth();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (!currentUser) {
            navigate(CLIENT_ROUTES.HOME);
        } else {
            const path = location.pathname;
            if (path.includes("/profile-page/orders")) setActiveTab(1);
            else if (path.includes("/profile-page/addresses")) setActiveTab(2);
            else if (path.includes("/profile-page/gift-vouchers")) setActiveTab(3);
            else if (path.includes("/profile-page/faqs")) setActiveTab(4);
            else setActiveTab(0);
        }
    }, [currentUser, navigate, location.pathname]);

    const handleChangePassword = () => {
        navigate(CLIENT_ROUTES.AUTH.CHANGE_PASSWORD);
    };

    const logoutHandler = () => {
        logout();
    };

    const handleTabClick = (index: number) => {
        setActiveTab(index);
        switch (index) {
            case 0:
                navigate("/profile-page/my-profile");
                break;
            case 1:
                navigate("/profile-page/orders");
                break;
            case 2:
                navigate("/profile-page/addresses");
                break;
            case 3:
                navigate("/profile-page/gift-vouchers");
                break;
            case 4:
                navigate("/profile-page/faqs");
                break;
            default:
                navigate("/profile-page/my-profile");
        }
    };

    return (
        <Grid item sx={{ marginLeft: 20 }} xs={2} marginRight={7}>
            <Paper elevation={0} sx={{ p: "2px 4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: 2, paddingY: 3, width: "100%", bgcolor: "#ECEEEF" }}>
                <span className="font-bold">Full Name</span>
                {currentUser?.fullName}
            </Paper>
            <div className="mt-5">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`pl-4 py-3 border-2 border-gray-light cursor-pointer rounded-none ${activeTab === index ? 'bg-gray-light font-medium' : 'font-thin'}`}
                        onClick={() => handleTabClick(index)}
                    >
                        <span className="text-md">{tab}</span>
                    </div>
                ))}
                <Button
                    onClick={handleChangePassword}
                    sx={{ width: "100%", color: "red", bgcolor: "white", border: "1px solid red", marginTop: 3, fontFamily: "Source Sans Pro, sans-serif", fontSize: "0.8rem", borderRadius: 0, "&:hover": { bgcolor: "red", color: "white", borderRadius: 0 } }}
                    variant="text"
                >
                    Change Password
                </Button>
                <Button
                    sx={{ width: "100%", color: "red", bgcolor: "white", border: "1px solid red", marginTop: 2, fontFamily: "Source Sans Pro, sans-serif", fontSize: "0.8rem", borderRadius: 0, "&:hover": { bgcolor: "red", color: "white", borderRadius: 0 } }}
                    variant="text"
                    onClick={logoutHandler}
                >
                    Logout
                </Button>
            </div>
        </Grid>
    );
};

export default TabsMenu;

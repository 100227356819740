import Grid from '@mui/material/Grid';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '../../../constant/client_route';

const AddAddresses = () => {
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate(); 

  const handleAddNewAddressClick = () => {
    navigate(CLIENT_ROUTES.ADDRESS.ADDRESS_FORM);
  };

  // const handleOpen = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setOpen(true);
  // };
  // const handleClose = () => setOpen(false);


  return (
    <Grid item xs>
      <Grid container className='gap-4'>
        <Grid item xs={5.5} className='border-dotted border-3 flex justify-center items-center cursor-pointer'>
          <div onClick={handleAddNewAddressClick} className='flex flex-col justify-center items-center py-20'>
            <div className='add-icon'>
              <Icon className='text-3xl font-bold text-secondary' icon="ic:outline-plus" />
            </div>
            <span className='text-sm font-bold text-secondary uppercase'>
              Add New Address
            </span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddAddresses;

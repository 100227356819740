import React, { useState, useEffect } from 'react';
import testImage from '../../../assets/testImage.jpg';
import { Icon } from '@iconify/react';
// import imageData from '../../../assets/imageData.json';

const BASE_URL = ``;
let VISIBLE_THUMBNAILS_COUNT = 1;

const ImageSlider = ({ imagesUrls }) => {
    const [activeImage, setActiveImage] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    useEffect(() => {
        VISIBLE_THUMBNAILS_COUNT = imagesUrls.length
        if (activeImage < startIndex) {
            setStartIndex(activeImage);
        } else if (activeImage >= startIndex + VISIBLE_THUMBNAILS_COUNT) {
            setStartIndex(activeImage - VISIBLE_THUMBNAILS_COUNT + 1);
        }
    }, [activeImage,startIndex]);

    const handleImageError = (e) => {
        e.target.src = testImage;
    };

    const handleThumbnailClick = (index) => {
        setActiveImage(index);
    };

    const handleUpArrow = () => {
        const newIndex = (activeImage - 1 + imagesUrls.length) % imagesUrls.length;
        setActiveImage(newIndex);
    };

    const handleDownArrow = () => {
        const newIndex = (activeImage + 1) % imagesUrls.length;
        setActiveImage(newIndex);
    };


    return (
        <div className='imageWrapper relative'>
            <div className='flex flex-col items-center rounded-xl bg-white p-2 mb-4'>
                <img
                    className="h-[450px] w-auto"
                    src={`${imagesUrls[0].primaryUrl}`}
                    alt="Product"
                    onError={handleImageError}
                />
                
            </div>
            <div className='flex items-center'>
                <div className="flex justify-center items-center w-10 h-10 rounded-full color-bgred group-hover:color-bgred/50 absolute top-1/2 -left-16">
                    <Icon icon="material-symbols:keyboard-arrow-left" className='cursor-pointer text-white' width="48" height="48" onClick={handleUpArrow} />
                </div>
                {imagesUrls.slice(startIndex, startIndex + VISIBLE_THUMBNAILS_COUNT).map((image:any, index) => (
                    <div key={index} className={`w-16 h-16 mr-2 p-2 rounded cursor-pointer ${startIndex + index === activeImage ? 'border border-primary' : 'border border-gray-light'}`}>
                        <img
                            src={image?.resizedUrls?.[2]?.url ? image?.resizedUrls?.[2]?.url:image.primaryUrl}
                            alt=""
                            className='w-auto h-full object-contain'
                            onClick={() => handleThumbnailClick(startIndex + index)}
                            onError={handleImageError}
                        />
                    </div>
                ))}
                <div className="flex justify-center items-center w-10 h-10 rounded-full color-bgred group-hover:color-bgred/50 absolute top-1/2  -right-16">
                    <Icon className='cursor-pointer text-white' icon="material-symbols:keyboard-arrow-right" width="48" height="48" onClick={handleDownArrow} />
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;

import { Icon } from "@iconify/react";
import styles from "./SearchBar.module.css"

const SearchBar = () => {
  return (
    <>
      <form className={styles.container}>
        <div className="relative">
          <div className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray left-3">
            <Icon icon="bitcoin-icons:search-filled" width="24" height="24" />
          </div>
          <input
            type="text"
            size={50}
            placeholder="Search for products, brands and more"
            className="w-full py-2 pl-12 pr-4  text-gray border rounded-3xl outline-none bg-white focus:bg-white focus:border-blue-light"
          />
        </div>
      </form>
    </>
  );
};

export default SearchBar;

import React from 'react'
import { Container } from '@mui/material'

const WholeSaleSolution = () => {
  return (
    <Container maxWidth={"lg"} className='wholesalesection pt-8 pb-6'>
        <div className='lg:mb-10 flex mb-4'>
        
            <div className='md:text-4xl text-xl font-semibold w-full lg:w-4/6 pr-2'>
                PushkarFoods Wholesale Solutions –
                <span className='font-normal italic'> Your Trusted Partner</span>
            </div>
            <div className='ContentWrap color-lightblack w-full'>
                <p className='mb-4'>
                    <strong>PushkarFoods</strong> is a solution for business customers such as retailers,
                    hotels, restaurants & caterers (HoReCa), and various business
                    customers who are looking for a reliable wholesale supplier. We can
                    help you stock up with high-quality products from a number of
                    multi-national brands and ensure that you meet the ever-evolving needs
                    of your business.
                </p>
                <p className='mb-4'>
                <strong>PushkarFoods</strong> is a B2B Cash and Carry wholesaler which has a wide
                presence in Delhi NCR. Our business customers can also order our wide
                range of products from our e-Commerce Website. With a dedicated sales
                team, we make sure to cater to your daily requirements with utmost
                priority and agility. PushkarFoods is your solution for wholesale online
                shopping. </p>
            </div>
    
        </div>
    </Container>
  )
}

export default WholeSaleSolution

import { Box, Grid, Paper, Divider } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import constant from "../../constant/constants";
import { useCart } from "../../context/CartContext";
import DropdownMenu from "../../utility/dropdown/dropdown-menu/DropdownMenu";
import DropdownItems from "../../utility/dropdown/dropdown-items/DropdownItems";
import { useWishlist } from "../../context/WishlistContext";

const CartCard = ({ product }) => {
  const navigate = useNavigate();
  const { removeCartItem, updateCartQuantity, updateCartSize } = useCart();
  const { addToWishlist, items } = useWishlist();

  const findWishlistItem = items.find((wishlist_item) => wishlist_item.id === product.id)

  const [selectedSize, setSelectedSize] = useState(product.cartSize || "XS");
  const availableSizes = product.sizeAvailable;
  const [sizeOpen, setSizeOpen] = useState(false);

  const [selectedQuantity, setSelectedQuantity] = useState(product.cartQuantity || "01");
  const availableQuantity = product.quantity
  const [quantityOpen, setQuantityOpen] = useState(false);

  const toggleSizeDropdown = () => {
    setSizeOpen(!sizeOpen);
  }

  const toggleQuantityDropdown = () => {
    setQuantityOpen(!quantityOpen);
  }
  const handleSizeClick = (option) => {
    setSelectedSize(option);
    updateCartSize(product.id, option)
    setSizeOpen(false);
  };

  const handleQuantityClick = (option) => {
    setSelectedQuantity(option);
    updateCartQuantity(product.id, option);
    setQuantityOpen(false);
  };

  const handleRemoveFromCart = () => {
    removeCartItem(product.id)
  }

  const handleMoveToWishlist = () => {
    addToWishlist(product, findWishlistItem);
    removeCartItem(product.id)
  }

  const handleCardClick = () => {
    navigate(`/product-details/${product.id}`);
  }

  const baseURL = constant.imageURI;

  return (
    <>
      <Paper className="w-full mt-3" elevation={1}>
        <Grid container>
          <Grid item xs={9}>
            <Box className="ml-5">
              <Box className=" pt-3 font-bold text-md cursor-pointer hover:text-primary" onClick={handleCardClick}>
                {product.product}
              </Box>
              <Box className="pt-1 text-sm">
                {product.category.name}
              </Box>

              <Box className=" pt-2 font-bold text-lg">₹ {product.price}</Box>
              <Box className="font-bold text-xs">MRP incl. of all taxes</Box>
              <Box className="mt-4" >
                <Box className="relative inline-flex  w-28">
                  <DropdownMenu toggleDropdown={toggleSizeDropdown} selectedOption={selectedSize} purpose={"Size"} />
                  {sizeOpen && (
                    <DropdownItems availableOptions={availableSizes} handleOptionClick={handleSizeClick} />
                  )}
                </Box>
                <Box className="relative inline-flex  w-36 ml-5">
                  <DropdownMenu toggleDropdown={toggleQuantityDropdown} selectedOption={selectedQuantity} purpose={"Quantity"} />
                  {quantityOpen && (
                    <DropdownItems availableOptions={availableQuantity} handleOptionClick={handleQuantityClick} />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <img
              className="w-[90%] mt-3 mb-3 pr-5"
              src={`${baseURL}/${product.images[0]}`}
              alt="error"
            />
          </Grid>
        </Grid>
        <Divider className="mt-1" sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
        <Box className="flex ">
          <div className="py-3 flex w-[40%] justify-center cursor-pointer">
            <button onClick={handleRemoveFromCart} className="px-3  items-center hover:text-primary">Remove</button>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2, bgcolor: "black" }} />
          {(findWishlistItem) ? "" : (<div className="py-3 w-[60%] flex justify-center border-solid border-r-1 border-gray-light hover:text-primary cursor-pointer">
            <button onClick={handleMoveToWishlist} className="px-3 items-center">Move to Wishlist</button>
          </div>)}
        </Box>
      </Paper>
    </>
  );
};

export default CartCard;



import React from 'react'

const TermsAndCondirtions = () => {
  return (
    <div className='terms-wrapper'>
      <p className='lg:mb-8 mb-4 text-2xl color-lightblack'><strong>Terms and Conditions</strong></p>
      <p className='pt-4'>
        These Terms and Conditions (“Terms”) form an integral part of the Merchant Agreement between Pushkar Foods and the enrolled merchant 
        (“Merchant”). By using the Pushkar Foods platform to purchase products (“Goods”), the Merchant agrees to be bound by these Terms.
      </p>
      <div className='pt-3'>
        <p><strong>1. Definitions</strong></p>
        <p><strong>Commencement Date:</strong> The date the Merchant completes the registration process and receives a confirmation 
          from Pushkar Foods.
        </p>
        <p><strong>Credit Facility:</strong>  A payment option allowing the Merchant to place orders on credit, subject to agreed terms.</p>
        <p><strong>Credit Limit:</strong>  The maximum outstanding amount allowed under the Credit Facility, which Pushkar Foods can adjust at
         its discretion.
        </p>
        <p><strong>Credit Period:</strong> The time within which the Merchant must clear all outstanding payments, as communicated by Pushkar Foods.</p>
        <p><strong>Goods:</strong> Products available on the Pushkar Foods platform, including perishable and nonperishable items.</p>
        <p><strong>Platform:</strong> The online technology platform provided by Pushkar Foods where the Merchant places orders.</p>
        <p><strong>Logistics Partner:</strong>  A third-party service responsible for delivering Goods to the Merchant.</p>
        <p><strong>Merchant Content:</strong> Any information or media provided by the Merchant on the Pushkar Foods platform.</p>
        <p><strong>Perishable Goods:</strong> Items such as dairy, vegetables, bakery products, and meats with a limited shelf life.</p>
        <p><strong>Price:</strong> The amount payable for Goods as indicated on the platform or communicated at the time of order placement.</p>
      </div>

      <div className='pt-3'>
        <p><strong>2. General Provisions</strong></p>
        <ol className='list-decimal ml-4'>
          <li><strong>Product Listings & Orders:</strong> Pushkar Foods will display available Goods and their Prices on the platform. The Merchant may browse and place 
        orders accordingly.</li>
          <li><strong>Order Confirmation & Delivery:</strong> Upon placing an order, the platform will provide the estimated delivery time. Merchants can track order status via the platform.</li>
          <li><strong>Compliance with Law:</strong> Both parties agree to comply with applicable laws. Pushkar Foods only sources Goods from third-party suppliers and is not 
          liable for manufacturing issues.</li>
          <li>
            <strong>Manufacturer Responsibility:</strong> Quality and packaging issues are the responsibility of the original manufacturer.
            Pushkar Foods will facilitate communication between the Merchant and the manufacturer in case of disputes.
          </li>
        </ol>
      </div>

      <div className='pt-3'>
        <p><strong>3. Term and Termination</strong></p>
        
        <p>
          <strong>Term:</strong> This agreement begins on the Commencement Date and continues until
          terminated.
        </p>

        <p><strong>Termination:</strong> Either party may terminate with seven (7) days’ notice.</p>

        <p><strong>Immediate Termination:</strong> Pushkar Foods may suspend services or terminate the agreement if the Merchant:</p>

        <p>
          ◦ Fails to make consecutive payments. <br/>
          ◦ Engages in fraudulent activities.<br/>
          ◦ Breaches applicable laws or these Terms.
        </p>
      </div>  

      <div className='pt-3'>
        <p><strong>4. Pricing and Quality</strong></p>
        <ol className='list-decimal ml-4'>
          <li><strong>Price Adjustments:</strong> Prices are subject to change without notice until the order is placed.</li>
          <li>
            <strong>Quality Check at Delivery:</strong> Merchants must inspect Goods upon delivery. No claims for defects or discrepancies will be accepted post-delivery
            unless raised immediately.
          </li>
          <li>
            <strong>Expiry Management:</strong> Merchants must use perishable items within their expiry dates. Pushkar Foods is not liable for quality issues arising from
            delayed usage.
          </li>
        </ol>
      </div>

      <div className='pt-3'>
        <p><strong>5. Delivery Policy</strong></p>
        <ol className='list-decimal ml-4'>
          <li><strong>Delivery Timelines:</strong> Orders will be delivered within the scheduled time slot, subject to a potential 30-minute variation.</li>
          <li>
            <strong>Acceptance of Delivery:</strong> Merchants must be present to accept deliveries. Failure to accept delivery may result in order cancellation
            without refund.
          </li>
          <li>
            <strong>Reverse Pickup:</strong> Reverse pickup requests must be submitted within seven (7) days of delivery. Pickup will occur only alongside a
            new order delivery.
          </li>
        </ol>
      </div>

      <div className='pt-3'>
        <p><strong>6. Merchant Obligations</strong></p>
        <p>
          <strong>• Accurate Information:</strong> Merchants must provide up-to-date contact, address, and delivery information.
        </p>

        <p>
          <strong>• Timely Payment:</strong> Payments must be made promptly as per the agreed terms. Merchants using the Credit Facility must clear dues
          within the specified Credit Period.
        </p>

        <p>
          <strong>• Elevator/Delivery Support:</strong> If delivery is required on a floor higher than the second, the Merchant must provide elevator
          access or assistance.
        </p>

        <p>
          <strong>• Order Cancellations:</strong> Cancellation requests will be reviewed, but Pushkar Foods does not guarantee cancellations or refunds
          after order confirmation.
        </p>
      </div>

      <div className='pt-3'>
        <p><strong>7. Invoice and Payments</strong></p>
        <ol className='list-decimal ml-4'>
          <li><strong>Invoices:</strong> Invoices will be provided at the time of delivery, reflecting the Price plus applicable taxes.</li>
          <li>
            <strong>Credit Facility Terms:</strong> <br/>
            • Merchants with approved Credit Facilities must settle invoices within the Credit Period. <br/>
            • Late payments may attract an interest charge of 18% per annum. <br/>
            • Pushkar Foods reserves the right to withhold further deliveries for overdue payments.
          </li>
          <li>
            <strong>Payment Reconciliation:</strong> Merchants must reconcile payments and taxes, including Goods and Service Tax (GST) and Tax Collected at
            Source (TCS), with the provided invoices.
          </li>
        </ol>
      </div>

      <div className='pt-3'>
        <p><strong>8.  Liability and Dispute Resolution</strong></p>
        <ol className='list-decimal ml-4'>
          <li><strong>Goods Liability:</strong> Pushkar Foods is not responsible for defects caused by manufacturers or delivery delays resulting from the Merchant’s actions.</li>
          <li><strong>Cash Deposits:</strong> Pushkar Foods does not accept cash deposits. Any such deposits will be treated in accordance with tax laws, and the Merchant will be liable for any penalties.</li>
          <li><strong>Indemnity:</strong> The Merchant agrees to indemnify Pushkar Foods against any losses arising from their failure to comply with these Terms.</li>
        </ol> 
      </div>

      <div className='pt-3'>
        <p><strong>9. Governing Law and Jurisdiction</strong></p>
        <p>These Terms are governed by and construed under the laws of India. Any disputes will be subject to the jurisdiction of the courts located in 
          the city where Pushkar Foods is headquartered.</p>
      </div>


      <div className='pt-3'>
        <p><strong>10. Quality Standards and Compliance with Law</strong></p>
        <p className='ml-2'>
          • Pushkar Foods sources products from third-party manufacturers and is not responsible for any manufacturing defects or quality issues. <br/>
          • All concerns regarding quality, ingredients, or packaging must be addressed with the manufacturer directly. <br/>
          • Pushkar Foods will assist merchants by facilitating communication with the manufacturer, if required.
        </p>
        <p><strong>Delivery Responsibility:</strong></p>
        <p className='ml-2'>• For goods delivered directly from the manufacturer to the merchant, Pushkar Foods disclaims liability for any 
          issues during transportation or delivery.</p>
      </div>

      <div className='pt-3'>
        <p><strong>11. Payments and Cash Deposits</strong></p>
        <p><strong>No Cash Deposits:</strong> Merchants must not deposit cash into Pushkar Foods' account. Any such deposits will be treated in 
        accordance with tax laws, and the merchant will be responsible for any resulting liabilities or penalties.</p>
      </div>

      <div className='pt-3'>
        <p><strong>12. Term and Termination</strong></p>
        <p>• Either party can terminate the agreement with <strong>7 days' prior written notice</strong>.</p>
        <p>
          • Pushkar Foods may terminate the contract immediately if: <br/>
          1. The merchant fails to make two consecutive payments. <br/>
          2. Any fraudulent activity is detected.<br/>
          3. The merchant becomes insolvent or fails to comply with applicable laws.
        </p>
        <p><strong>Post-Termination Obligations:</strong> Merchants remain liable for payments related to all orders placed prior 
        to termination, even if delivery occurs afterward.</p>
      </div>

      <div className='pt-3'>
        <p><strong>13. Pricing and Payment Terms</strong></p>
        <p><strong>Pricing:</strong></p>
        <p className='ml-2'>
            •  Goods will be sold at the price listed on the Pushkar Foods platform, subject to changes before order placement. <br/>
            •  Prices can be revised at Pushkar Foods' discretion without prior notice.
        </p>
        <p><strong>Credit Facility:</strong></p>
        <p className='ml-2'>
            • Merchants using the credit facility must clear invoices within the agreed credit period.<br/>
            • A 18% annual interest will be charged on overdue payments.<br/>
            • Pushkar Foods may use undated cheques (UDC) or bank guarantees to recover outstanding amounts in case of default.
        </p>
      </div>

      <div className='pt-3'>
        <p><strong>14. Goods Delivery and Returns</strong></p>
        <p><strong>Checking Goods at Delivery:</strong></p>
        <p>Pushkar Foods follows a strict no-returns policy for all products purchased through our platform. Due to the perishable nature of many 
          items and to maintain the highest standards of hygiene and quality, we are unable to accept returns under any circumstances.</p>

        <p>We ensure that all products are carefully inspected and packed before delivery to meet our quality standards. If you receive an incorrect, 
          damaged, or defective product, please report the issue immediately to our <strong>Customer Support</strong> at [Email Address] within 24 hours 
          of delivery. We will review such cases and, at our sole discretion, offer a suitable resolution, such as a refund or replacement, 
          where applicable.
        </p>

        <p className='ml-2'>• Merchants must inspect the quality and quantity of goods at the time of delivery. 
        <strong> No post delivery complaints</strong> will be accepted.</p>

        <p><strong>Perishable Goods:</strong></p>

        <p className='ml-2'>• Pushkar Foods will not refund any spoiled perishable goods if delivery is delayed due to the merchant’s 
        failure to accept the order on time.</p>
      </div>  

    </div>
  )
}

export default TermsAndCondirtions

import { SERVER_ENDPONTS } from "../../constant/server_endpoints";

import axiosClient from "../axiosClient";


export function addProductToCart(payload={}){
    return axiosClient.post(SERVER_ENDPONTS.CART.ADD_TO_CART)
}

export function removeProductFromCart(payload={}){
    return axiosClient.post(SERVER_ENDPONTS.CART.REMOVE_FROM_CART)
}

export function updateCart(payload={}){
    return axiosClient.put(SERVER_ENDPONTS.CART.UPDATE_CART)
}

export function emptyCart(payload={}){
    return axiosClient.delete(SERVER_ENDPONTS.CART.EMPTY_CART)
}

export function getAllCart(){
    return axiosClient.get(SERVER_ENDPONTS.CART.GET_ALL_CART)
}

export function findInCart(){
    return axiosClient.get(SERVER_ENDPONTS.CART.FIND_IN_CART); 
}
import React from 'react'
import { Container } from '@mui/material'

const DeliveryDetails = () => {
  return (
    <Container maxWidth={"lg"} className='deliverydetails'>
      <div className='md:text-4xl text-xl font-semibold color-lightblack mt-10'>Delivery</div>
      <div className="wrap-delivery-page bg-white p-8 mt-6 lg:mb-14 mb-8 border-radius-50 color-lightblack drop-shadow-lg">
        <p className='font-bold'>SHIPPING & DELIVERY OF PRODUCTS</p>
        <div className='font-medium'>
          <p>
            LOTS Wholesale Solutions (‘LOTS’) will deliver the Products only at the address provided by you at the time of registration with LOTS. 
            The Products will be delivered till the ground floor of the registered address premises, 
            or to the point where access to the vehicle is possible. LOTS will attempt to deliver your order in the fastest time possible.
          </p>
          <p>
            Shipping is possible only for certain pin-codes, lying within the delivery zones as defined by LOTS from time to time. The delivery zones 
            for each of the LOTS Stores along with the pin codes are mentioned under the heading "Delivery Zones" in this policy.
          </p>
          <p>
            LOTS shall facilitate delivery of an order placed by you at selected delivery location falling under respective delivery zones.
          </p>
          <p>
            You will verify the ordered and billed Products against the order delivered, in case of any damage or discrepancy in the order, 
            you will bring the same to the notice of the delivery personnel and take acknowledgment for such discrepancy or damage from 
            the delivery personnel. For detailed terms and condition please refer to our return policy. Subject to inspection, for prepaid orders, 
            the refund amount against the accepted discrepancy or damaged Product shall be refunded back in the source account. In cases, wherein you 
            have utilised the LOTS E – Wallet, the refund shall be credited back as ‘LOTS Credit’ in your LOTS E- wallet account.
          </p>
          <p>
            Post shipment of the order, if it is observed that LOTS is unable to deliver your order due to the wrong or incomplete address provided, 
            LOTS reserves the right to cancel the order. For prepaid orders, you will be refunded the order amount back to your source account, 
            excluding the shipping charges and/or any other cost incurred by LOTS in this regard.
          </p>
        </div>
        <p className='font-bold'>Shipping Charges</p>
        <div className='font-medium'>
            <p>
              Shipping charges shall be calculated at the time of placing the order and shall be indicated the checkout page and displayed in the 
              invoice. These charges must be paid in full at the time of placing the order through any mode or at the time of delivery for cash on 
              delivery orders, as the case may be. The shipping charges are calculated on the basis of multiple factors, including but not limited to 
              the kind of items orders, weight, bulk purchases, delivery location etc. We strive to provide the most accurate and cost-effective 
              shipping options for our customers. It is important to note that these charges are subject to change and may vary based on the specific 
              details of your order. By placing the order, you agree to the calculated shipping charges as presented to you.
            </p>
            <p>
             You may reach out to LOTS’ Customer Care for any further information pertaining to shipping charges.
            </p>
            <p>
              For our Ghaziabad Micro-Hub, we are currently levying a flat charge of INR 30/-(Rupees thirty only) as a shipping charge on the 
              total invoice value (including applicable tax) if the order value is less than INR 6000/- (Rupees Six thousand only).
            </p>
            <p>
              LOTS reserves the right to modify the shipping charges at any time without prior notice.
            </p>
            <p>
              Further, if your prepaid order is cancelled before the dispatch, full shipping charges shall be refunded back in your source account. 
              In cases, wherein you have used LOTS E-wallet account, the applicable amount shall be credited back as ‘LOTS Credit’. In case you wish 
              to reject/return any Product from the order at the time of delivery, the cost of shipping charges will be borne by you Consequently, 
              no refund for the shipping charge will be issued against the rejected or returned product.
            </p>
            <p>
              You hereby agree that you shall be responsible for shipping charges incurred for your order regardless of your knowledge of such 
              shipping charges. You acknowledge and affirm that LOTS is authorised to collect such charges for the purpose of providing seamless 
              delivery and quality service till your doorstep.
            </p>
        </div>
      </div>
    </Container>
  )
}

export default DeliveryDetails

import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { CLIENT_ROUTES } from '../constant/client_route';

const PrivateRoute = ({ element }) => {
    const { currentUser } = useAuth();
    const location = useLocation();

    return currentUser ? element : <Navigate to={CLIENT_ROUTES.AUTH.LOGIN} state={{ from: location }} 
    replace  />;
};

export default PrivateRoute;

const AccordionItem = ({ label }) => {
    return (
      <div className="flex justify-start items-center cursor-pointer">
        {/* <input type="checkbox" className="text-primary border-gray-light rounded" /> */}
        <label className="ms-2 text-sm font-medium text-gray-dark cursor-pointer">{label}</label>
      </div>
    );
  };
  
  export default AccordionItem;
  
import { SERVER_ENDPONTS } from '../../constant/server_endpoints'
import axiosClient from '../axiosClient'

export function getCatalogList(params = {}) {
    return axiosClient.get(SERVER_ENDPONTS.CATALOG.CATALOG_LIST, { params: params })
}

export function getRequiredProductFromCatalog(params={}){
    return axiosClient.get(SERVER_ENDPONTS.CATALOG.PRODUCT_DETAILS,{params:params})
}

export function getCategories(params={}){
    return axiosClient.get(SERVER_ENDPONTS.CATEGORY,{params:params})
}

export function getBrands(params={}){
    return axiosClient.get(SERVER_ENDPONTS.BRAND,{params:params})
}

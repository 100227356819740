import { createBrowserRouter} from "react-router-dom";
import App from "../App";
import { lazy } from "react";
import NotFound from "../pages/not-found/NotFound";
import ProductCardContainer from "../pages/products/product-listing/ProductCardContainer";
import ProductPage from "../pages/products/product-page/ProductPage";
import EmailLogin from "../pages/auth/EmailLogin";
import SignIn from "../pages/auth/SignIn";
import ProfilePage from "../pages/profile/profile-page/ProfilesPage";
import OrderPage from "../pages/orders/OrderPage";
import WishlistPage from "../pages/wishlist/WishlistPage";
import CartPage from "../pages/cart/CartPage";
import AuthPage from "../pages/auth/AuthPage";
import ValidateOTP from "../pages/auth/ValidateOTP";
import SignUp from "../pages/auth/SignUp";
import ValidateEmailOTP from "../pages/auth/ValidateEmailOTP";
import ChangePassword from "../pages/auth/ChangePassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import EditProfile from "../pages/profile/edit-profile/EditProfile";
import AddressForm from "../pages/address-management/address-form/AddressForm";
import Addresses from "../pages/address-management/address-layout/Addresses";
import AddAddresses from "../pages/address-management/add-address/AddAddress";
import ContactUs from "../pages/static/ContactUs";
import About from "../pages/static/About";
import OurStory from "../pages/static/OurStory";
import DeliveryDetails from "../pages/static/DeliveryDetails";
import FrequentlyAskedQuestions from "../pages/static/FrequentlyAskedQuestions";
import HelpAndSupport from "../pages/static/help-and-support/HelpAndSupport";
import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("../pages/home/Home"));



export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "products/:category?",
        element: <ProductCardContainer />,
      },
      {
        path: "product-details/:sku",
        element: <ProductPage />
      },
      {
        path: "profile-page",
        element: <PrivateRoute element={<ProfilePage />} />,
        children: [
          {
            path: "my-profile",
            element: <EditProfile />
          },
          {
            path: "orders",
            element: <OrderPage />
          },
          {
            path: "addresses",
            element: <Addresses />,
            children: [
              {
                path: "",
                element: <AddAddresses />
              },
              {
                path: "address-new",
                element: <AddressForm />
              },
            ]
          },
        ]
      },
      // {
      //   path: "auth",
      //   element: <AuthPage />,
      //   children: [
      //     {
      //       path: "login",
      //       element: <SignIn />,
      //     },
      //     {
      //       path: "login/email",
      //       element: <EmailLogin />
      //     },
      //     {
      //       path: "login/verify-otp",
      //       element: <ValidateOTP />
      //     },
      //     {
      //       path: "login/email/verify-email",
      //       element: <ValidateEmailOTP />
      //     },
      //     {
      //       path: "signup",
      //       element: <SignUp />
      //     },
      //     {
      //       path: "change-password",
      //       element: <ChangePassword />
      //     },
      //     {
      //       path: "forgot-password",
      //       element: <ForgotPassword />
      //     }
      //   ]
      // },
      {
        path: "wishlist",
        element: <PrivateRoute element={<WishlistPage />} />
      },
      {
        path: "cart",
        element: <PrivateRoute element={<CartPage />} />
      },
      {
        path: "contact-us",
        element: <ContactUs />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "ourstory",
        element: <OurStory />
      },
      {
        path: "delivery",
        element: <DeliveryDetails />
      },
      {
        path: "faq",
        element: <FrequentlyAskedQuestions />
      },
      {
        path: "help&support",
        element: <HelpAndSupport />
      },
    ],
  },


]);

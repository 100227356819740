import { useEffect, useState } from "react";
import { Container, Grid, Box, Modal } from "@mui/material";
import CartCard from "../../components/cart-card/CartCard";
import { useCart } from "../../context/CartContext";
import { useNavigate } from "react-router-dom";
import PaymentGateway from "../payment-gateway/PaymentGateway";
import { getAllCart } from "../../api/lib/cart";

const CartPage = () => {
  const navigate = useNavigate();

  const [productIDs, setProductIDs] = useState([]);
  const [checkoutCost, setCheckoutCost] = useState(0);
  // const [cartItems, setCartItems] = useState<Product[]>([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { cartItems } = useCart();
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await getAllCart();
        localStorage.setItem('cartID', response.data.data.data._id);
        const totalCost = response.data.data.data.totalPrice;
        setCheckoutCost(totalCost);
        const cartDetails = response?.data?.data?.data?.cartDetails;
        const catalogIds = cartDetails?.map(item => item.catalogId);
        setProductIDs(catalogIds);
      } catch (error) {
        console.error('Error Fetching IDs', error);
      }
    };
  
    fetchCartItems();
  }, []); // Empty dependency array to run only once on mount
  
  // useEffect(() => {
  //   const fetchCartProducts = async () => {
  //     try {
  //       const productPromises = productIDs.map(id => getRequiredProductFromCatalog({ sku: id }));
  //       const productResults = await Promise.all(productPromises);
  //       const products = productResults.map(result => result.data.data);
  //       setCartItems(products);
  //     } catch (error) {
  //       console.error("Error Loading Products", error);
  //     }
  //   };
  
  //   if (productIDs.length > 0) {
  //     fetchCartProducts();
  //   }
  // }, [productIDs]); 


  return (
    <>
      <Container className="mt-3" maxWidth="lg">
        <div className="text-xl font-bold mb-3 mt-3 ml-2 pb-1 border-solid border-b-[3px] border-primary inline-block">
          My Bag: <span className="text-lg font-light   ">{cartItems?.length} item(s)</span>
        </div>
        {cartItems?.length < 1 && (
          <Box className="mt-3 flex justify-center items-center flex-col" >
            <Box className="text-xl font-bold ">You dont have any items in your cart !</Box>
            <button onClick={() => { navigate("/") }} className="mt-2 px-3 py-1 text-md bg-primary text-white rounded-sm">Continue Shopping</button>
          </Box>
        )}
        {cartItems?.length > 0 &&
          (<Grid className="flex justify-between" container>
            <Grid className="mt-1 mb-1 " item xs={6.5}>
              <div className="px-2 py-2 flex border-solid border-2 border-gray justify-between items-center rounded-md">
                <div className="pl-2 flex flex-col ">
                  <span className="text-sm text-secondary font-bold tracking-wider capitalize">Deliver to: John Doe, 480001</span>
                  <span className="text-xs tracking-wider">John Doe
                    P.O. Box 283 8562 Fusce Rd.
                    Frederick Nebraska 20620</span>
                  <span className="text-xs tracking-wider">Estimated Delivery: 5days</span>
                </div>
                <div className="pr-3">
                  <button className="text-secondary text-sm uppercase font-bold">Change</button>
                </div>
              </div>
              {cartItems.map((product) => (
                <CartCard key={product._id} product={product} />
              ))}
            </Grid>
            <Grid className="mt-1 mb-1 " item xs={5}>
              <div className="border-solid border-2 border-gray rounded-sm flex flex-col">
                <div className="pl-5 py-3 flex items-center text-sm font-bold uppercase bg-secondary text-white rounded-sm tracking-wider">
                  Price Summary
                </div>
                <div className="mt-4 px-3" >
                  <div className="flex justify-between text-sm">
                    <span className="">Total MRP (Incl. of taxes) </span>
                    <span>₹{checkoutCost}</span>
                  </div>
                  <div className="flex justify-between mt-2 text-sm">
                    <span className="">Delivery Fee </span>
                    <span className="text-green">FREE!</span>
                  </div>
                  <div className="flex justify-between mt-2 text-sm">
                    <span className="">Total Discount </span>
                    <span>- ₹0</span>
                  </div>
                  <div className="flex justify-between mt-2 text-md font-bold">
                    <span className="">Subtotal </span>
                    <span>₹{checkoutCost}</span>
                  </div>
                  <div className="mt-4 mb-3 flex justify-between items-center">
                    <div className=" inline-flex flex-col content-center">
                      <span className="text-sm text-center w-full">Total</span>
                      <span className="text-lg font-bold text-center w-full">₹{checkoutCost}</span>
                    </div>
                    <button id="rzp-button1" onClick={handleOpen} className=" px-5 py-2 bg-secondary text-white font-bold tracking-wider uppercase rounded-md"> checkout</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          )}
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <PaymentGateway />
        </>
      </Modal>
    </>
  );
};

export default CartPage;

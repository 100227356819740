import React from 'react'

interface MyComponentProps {
  toggleDropdown: () => void;
  selectedOption: any;
  purpose:string; 
}

const DropdownMenu: React.FC<MyComponentProps> = ({ toggleDropdown, selectedOption,purpose }) => {
  return (
    <div>
      <button
        type="button"
        className="inline-flex justify-between w-full rounded-sm border border-gray-light pl-3  py-1 bg-white text-sm text-gray-700 hover:bg-gray-light "
        id="availableSizes-menu"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        {purpose}: {selectedOption}
        <svg className="mr-1 ml-1 h-5 w-5  justify-end" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true">
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </button>
    </div>
  )
}

export default DropdownMenu
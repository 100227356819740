import productData from "../../assets/productData/data.json";
import constant from "../../constant/constants";
import { Icon } from '@iconify/react';

const OrderCard = () => {
  const product1 = productData[0];
  const baseURL = constant.imageURI;
  return (
    <div>
      <div className="container-box flex flex-col border-2 rounded-lg border-gray-light shadow-md cursor-pointer">
        <div className="order-bar flex justify-between w-full border-b border-gray-light  px-2 py-2 ">
          <div className="flex items-center justify-start gap-2">
            <div className="">
              <Icon icon="teenyicons:tick-circle-solid" className="text-2xl text-green" />
            </div>
            <div className="flex flex-col ">
              <span className="text-green text-lg">Order Delivered</span>
              <span className="text-sm font-thin text-gray">Order placed on 8 Jan 2024</span>
            </div>
          </div>
          <div className="flex items-center right-0">
            <Icon icon="iconamoon:arrow-right-2-duotone" width="24" height="24" />
          </div>
        </div>
        <div className="body flex items-start px-3 py-3 gap-3">
          <div className="item-image ">
            <img className="h-56 w-full rounded-md" src={`${baseURL}/${product1.images[0]}`} alt="error" />
          </div>
          <div className="item-details flex flex-col gap-2">
            <span className="product-brand font-semibold text-lg tracking-wide">{product1.product}</span>
            <span className="text-md text-gray-dark">Men's Blue Oversized Plus Size Joggers</span>
            <span className="text-md text-gray-dark">Size: M</span>
            <span className="text-md text-gray-dark">Price: ₹999</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;

import React, { useState, useEffect, useContext } from 'react';
import { addProductToCart, removeProductFromCart } from '../api/lib/cart'
import { Product } from "../interfaces/product.interfaces";

const CartContext = React.createContext();


const CartContextProvider = ({ children }) => {
    
    const [cartItems, setCartItems] = useState([]);

    const findInCart= async(payload)=>{

    }

    const addToCart= async(payload)=>{
        try{
            const response= await addProductToCart(payload)
            console.log(response.data); 
            const cartId= response.data.data.cartId
            localStorage.setItem('cartId',cartId) ;
        }
        catch(error){
            console.error('Error adding to cart',error); 
        }
    }
    
    const removeCartItem = async(payload) => {
        try {
            const response= await removeProductFromCart(payload); 
            console.log(response.data); 
        } catch (error) {
            console.error('Error removing from cart',error); 
        }
    }


    // const updateCartQuantity = (item_id, quantity) => {
    //     const updatedItems = cartItems.map((item) =>
    //         item.id === item_id ? { ...item, cartQuantity: quantity } : item)
    //     setCartItems(updatedItems)
    // }

    // const updateCartSize = (item_id,size) => {
    //     const updatedItems = cartItems.map((item) =>
    //         item.id === item_id ? { ...item,cartSize:size } : item)
    //     setCartItems(updatedItems)
    // }


    const emptyCart = () => { setCartItems([]) };


    const contextValue = {
        cartItems,
        setCartItems,
        addToCart,
        removeCartItem,
        emptyCart,
        // updateCartQuantity,
        // updateCartSize
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

const useCart = () => useContext(CartContext);
export { useCart, CartContextProvider }; 
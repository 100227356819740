import React, { useState, useContext} from "react";
import { addOrRemoveFromWishlist } from "../api/lib/wishlist";

const WishlistContext = React.createContext();

const WishlistContextProvider = ({ children }) => {
  const [wishlistProducts, setWishlistProducts] = useState([]);

  const addToWishlist = (data) => {
    addOrRemoveFromWishlist({ catalog: data, reqType: 1 }).then((response) => {
      console.log(response.data);
      setWishlistProducts((prevWishlist) => [...prevWishlist, data])
    }).catch((error) => {
      console.error('Error adding or removing from wishlist', error);
    })
  }

  const removeFromWishlist = (id) => {
    addOrRemoveFromWishlist({ catalogId: id, reqType: 2 }).then((response) => {
      console.log(response.data)
      setWishlistProducts((prevWishlist) =>
        prevWishlist.filter((product) => product.catalogId !== id))
    }).catch((error) => {
      console.error(`Error Removing From Wishlist`, error)
    })
  };

  // const findInWishlist = (id) => {
  //   if (wishlistProducts) {
  //     const result = wishlistProducts.some((product) => product.catalogId === id);
  //     return result;
  //   }
  // };

  // const emptyWishlist = () => setItems([]);

  const contextValue = {
    addToWishlist,
    removeFromWishlist,
    // findInWishlist,
    wishlistProducts,
    setWishlistProducts
  };

  return (
    <WishlistContext.Provider value={contextValue}>
      {children}
    </WishlistContext.Provider>
  );
};


const useWishlist = () => useContext(WishlistContext);

export { useWishlist, WishlistContextProvider };

import React from 'react';
import { Container } from '@mui/material';
import { Accordion, AccordionTab } from 'primereact/accordion';
        

const FrequentlyAskedQuestions = () => {
  return (
    <Container maxWidth={"lg"} className='faqwrapper'>
      <div className='md:text-4xl text-xl font-semibold color-lightblack mt-10'>Frequently Asked Questions</div>
      <div className="wrap-faq-page bg-white p-8 mt-6 lg:mb-14 mb-8 border-radius-50 color-lightblack drop-shadow-lg">
        <Accordion activeIndex={0}>
          <AccordionTab header="I am unable to add products to cart. It says MOQ / Minimum Quantity not met? What is MOQ / Minimum Quantity?">
            <p className="m-0">
              MOQ is Minimum Order Quantity. MOQ is set by sellers and can be different for different products / listings. 
              A buyer needs to purchase at least the mentioned MOQ to continue the trade. 
              Please chat with seller to understand if he is willing to allow the trade of her product at a lower MOQ.
            </p>
          </AccordionTab>
          <AccordionTab header="Why am I getting MOV not met? What is MOV?">
              <p className="m-0">
                MOV stands for Minimum Order Value. MOV applies at a single order per seller basis. 
                Sellers put MOV to ensure a minimum business per order per buyer. 
                Please chat with seller to negotiate MOV for your order.
              </p>
          </AccordionTab>
          <AccordionTab header="Payments: How do I pay for the purchase?">
              <p className="m-0">
                You can pay by cash once the order is delivered to you. There is provision to purchase the products on credit but it is available on few sellers. 
                Please reach out to Udaan Support to check if you can avail credit for your purchase(s).
              </p>
          </AccordionTab>
          <AccordionTab header="How do I get the purchased products? Who provides the courier services?">
              <p className="m-0">
                As a buyer, you need not worry about arranging courier or any 3rd party couriers to pickup and deliver your order. 
                Udaan Logistics arranges for the order to be picked up.
              </p>
          </AccordionTab>
          <AccordionTab header="Who pays for the courier?">
              <p className="m-0">
              As a buyer, you are liable to pay for the courier services. Please check the Review Order Page to get an estimate of the logistics charges. 
              You can also refer to the <a href="https://udaan.com/rate-card" className='underline'>rate card (here)</a> to understand cost to courier goods between different parts of India.
              </p>
          </AccordionTab>
          <AccordionTab header="When do I need to pay for the courier?">
              <p className="m-0">
                Courier charges are required to be paid along with the payment for the order to the seller. 
                You can check the shipping / logistics charges on the order page.
              </p>
          </AccordionTab>
        </Accordion>
      </div>
    </Container>
  )
}

export default FrequentlyAskedQuestions

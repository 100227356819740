import { useMemo } from 'react'

export const DOTS = '...';

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
}

export const usePagination = ({  totalPageCount, pageLimit, siblingCount = 1, currentPage }) => {
    const paginnationRange = useMemo(() => {
        const totalPageNumbers = siblingCount + 5;
        //*Case 1: Number of pages< pageNumbers we want to show, i.e. No Dots return range[1,totalPageNumbers]
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        //* Calculate Left and Right Sibling 
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        //*Show dots only when leftSiblingIndex>2 and rightSiblingIndex<totalPageCount-2
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        //*Case 2: No left dots to show but right dots to show
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);
            return [...leftRange, DOTS, totalPageCount];
        }

        //* Case 3: No right dots to show but left dots to show
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, DOTS, ...rightRange]
        }

        //* Case 4: Show Both Side Dots
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }


    }, [siblingCount, currentPage,totalPageCount])
    return paginnationRange;
}

// ProductCard.js
import { useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IMAGE_HUB } from '../../constant/image_hub';
import ProductImg from '../../assets/product.png';
import { addProductToCart } from '../../api/lib/cart';
import testImage from '../../assets/icons/pushkar_foods_thumbnail.png'

const MAX_TITLE_LENGTH = 16;

const ProductCard = ({ product }) => {
  // const { addToWishlist, removeFromWishlist } = useWishlist();
  // const [isWishlist, setIsWishlist] = useState(product?.isWishlist);
  const [imageSrc, setImageSrc] = useState(product.mediaUrls[0]);
  const [quantity, setQuantity] = useState(1); // Initial quantity

  const handleIncrement = (e) => {
    e.stopPropagation()
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = (e) => {
    e.stopPropagation()
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const addToCart = (e) => {
    e.stopPropagation()
    console.log(product._id);
    addProductToCart({ catalogId: product._id, quantity: quantity })
  }

  // const handleWishlistButton = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     catalogId: product._id,
  //     sku: product.sku,
  //     name: product.name,
  //     description: product.description,
  //     imagesUrls: product.mediaUrls,
  //   };

  //   if (isWishlist) {
  //     await removeFromWishlist(product._id);
  //     setIsWishlist(false);
  //   } else {
  //     await addToWishlist(data);
  //     setIsWishlist(true);
  //   }
  // };

  const handleImageError = () => {
    setImageSrc(IMAGE_HUB.SAMPLE_PRODUCT_IMAGE);
  };

  const percentageOff = product?.price
    ? Math.round(
      ((product.price.mrp - product.price.selling) / product.price.mrp) * 100
    )
    : 0;
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/product-details/${product?.sku}`)
  }

  return (
    <Grid onClick={handleCardClick} className='cursor-pointer'>
      <div className='p-2 rounded-3xl bg-white'>
        {/* <div className="flex justify-end mr-1">
          <button
            onClick={(e) => handleWishlistButton(e)}
            className="mt-1 absolute"
          >
            <FavoriteIcon
              className={isWishlist ? 'text-primary ' : 'text-black'}
              fontSize="medium"
            />
          </button>
        </div> */}
        <div id='product-image' className='flex justify-center py-4'>
          <img
            className="w-48 h-48"
            src={(product?.mediaUrls && product.mediaUrls.length > 0) ? product.mediaUrls[0].resizedUrls?.[2]?.url : testImage}
            alt="Product"
          />
        </div>

        {/* <div id='divider' className='w-full  border'></div> */}
        <div id='product-info' className="infowrapper card-textbg p-4 rounded-3xl">
          {/* <div className="flex pt-0.5 pb-0.5">
            <div className="text-sm font-bold capitalize">
              {product?.brands[0]?.name?.length > MAX_TITLE_LENGTH
                ? `${product?.brands[0].name.substring(0, MAX_TITLE_LENGTH)}...`
                : product?.brands[0]?.name || 'No Brand'}
            </div>
          </div> */}
          {/* <Box>
            <Rating
              size="small"
              name="read-only"
              value={3.5}
              precision={0.5}
              readOnly
            />
          </Box> */}

          <div className="text-xl font-semibold text-black capitalize">
            {product?.name.length > MAX_TITLE_LENGTH ? `${product.name.slice(0, MAX_TITLE_LENGTH)}...` : product.name}
          </div>

          {/* <span className='inline-flex pb-4'>Peti (850 gm)</span> */}

          <div className="flex mt-0.5 items-center">
            <div className="text-black text-xl font-semibold">₹ {product?.price?.selling}
              {/* <span className="packageCount ml-2 font-semibold color-textgrey text-base">(1 X 12Pcs)</span> */}
            </div>

            {/* {product?.price?.mrp && (
              <div className="text-gray ml-2 text-xs line-through decoration-red decoration-[1.5px]">
                ₹{product?.price?.mrp}
              </div>
            )}
            {percentageOff > 0 && (
              <div className="text-xs ml-3 text-secondary uppercase">
                ({percentageOff}% off)
              </div>
            )} */}
          </div>
          <div id='counter-add-container' className='flex items-center justify-between mt-4'>
            <div id='quantity-counter'>
              <form className="max-w-xs mx-auto">
                <div className="flex items-center bg-white countBtn">
                  <button
                    type="button"
                    id="decrement-button"
                    onClick={(e) => handleDecrement(e)}
                    className="flex items-center justify-center bg-white"
                  >
                    <span className="text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="4"
                        fill="none"
                        viewBox="0 0 20 4"
                      >
                        <path
                          fill="#343539"
                          d="M17.737 3.886H2.444c-1.008 0-1.821-.728-1.821-1.63 0-.902.813-1.63 1.82-1.63h15.294c1.008 0 1.82.728 1.82 1.63 0 .902-.812 1.63-1.82 1.63z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                  <div className="flex items-center justify-center text-black text-md font-semibold px-2">
                    {quantity}
                  </div>
                  <button type="button" id="increment-button" onClick={(e) => handleIncrement(e)} className="flex items-center justify-center bg-white">
                    <span className="text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="none"
                        viewBox="0 0 19 19"
                      >
                        <path
                          fill="#343539"
                          d="M17.338 7.723H11.35V1.971c0-.86-.727-1.559-1.623-1.559s-1.623.698-1.623 1.56v5.751H2.116c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56v-5.752h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
            <div id='add-to-cart-button' onClick={(e) => addToCart(e)} className='addButton'>
              <button className='flex items-center add-btn'>
                Add  <svg
                  className='ml-2'
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="18"
                  fill="none"
                  viewBox="0 0 19 18"
                >
                  <path
                    fill="#fff"
                    d="M17.29 7.311h-5.987V1.56C11.303.7 10.576 0 9.68 0S8.057.698 8.057 1.56V7.31H2.069c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56V10.43h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                  ></path>
                </svg>
                {/* <span className='text-black z-10 pr-2'>Add +</span> */}
                {/* <span className=' z-10 text-xl'>+</span>
                <div className='absolute inset-0 bg-green-light opacity-40 z-0'></div> */}
              </button>
            </div>
          </div>
        </div>

      </div>
    </Grid>
  );
};

export default ProductCard;

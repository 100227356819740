import { Container, Grid } from '@mui/material';

const ProductPageShimmer = () => {
    return (
        <>
            <Container sx={{ marginTop: 5 }} maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container className='flex animate-pulse'>
                            <Grid item xs={12}>
                                <div className='w-[450px] h-[550px] bg-gray-light'></div>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={4.5}>
                        <div className='h-[23px] w-64 bg-gray-light'></div>
                        <div className='mt-2 h-[20px] w-48 bg-gray-light'></div>
                        <div className='mt-4 h-[23px] w-40 bg-gray-light'></div>
                        <div className='mt-2 h-[23px] w-44 bg-gray-light'></div>
                        <div className='mt-4 h-[17px] w-64 bg-gray-light'></div>
                        <div className='mt-3 h-[17px] w-64 bg-gray-light'></div>
                        <div className='mt-4 h-[28px] w-44 bg-gray-light'></div>
                        <div className='mt-4 h-[17px] w-60 bg-gray-light'></div>
                        <div className='flex mt-4'>
                            <div className=' mr-3 h-[36px] w-56 bg-gray-light'></div>
                            <div className=' h-[36px] w-56 bg-gray-light'></div>
                        </div>
                        <div className='flex mt-4'>
                            <div className='h-[17px] w-20 bg-gray-light'></div>
                            <div className=' h-[17px] ml-2 w-[17px] bg-gray-light'></div>
                            <div className=' h-[17px] ml-2 w-[17px] bg-gray-light'></div>
                            <div className=' h-[17px] ml-2 w-[17px] bg-gray-light'></div>
                            <div className=' h-[17px] ml-2 w-[17px] bg-gray-light'></div>
                        </div>
                        <div className='mt-4 h-[17px] w-40 bg-gray-light'></div>
                        <div className='mt-3 h-[33px] w-full bg-gray-light'></div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default ProductPageShimmer;

import { Link } from "react-router-dom"
import { CLIENT_ROUTES } from "../../constant/client_route"

const NotFound = () => {
    return <>
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-blue-dark">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-dark sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-gray-dark">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to={CLIENT_ROUTES.HOME} className="rounded-md bg-blue-dark px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue">Go back home</Link>
                    <Link to={CLIENT_ROUTES.HOME} className="text-sm font-semibold text-gray-dark">Contact support <span aria-hidden="true">&rarr;</span></Link>
                </div>
            </div>
        </main></>
}

export default NotFound
import { DOTS, usePagination } from '../../utility/usePagination/usePagination';

const Pagination = ({ onPageChange, totalPageCount, siblingCount = 1, currentPage, pageLimit }) => {
  const paginationRange = usePagination({ totalPageCount, currentPage, siblingCount, pageLimit });

  if (currentPage === 0 || !paginationRange || paginationRange?.length < 2) {
    return null;
  }

  const handleNext = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`flex justify-center items-center space-x-2`}>
      <li
        className={`px-3 py-1 rounded cursor-pointer ${currentPage === 1 ? 'text-gray-light pointer-events-none' : 'text-primary hover:bg-gray-light'}`}
        onClick={handlePrevious}
      >
        &larr;
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li key={index} className="px-3 py-1 text-gray">&#8230;</li>;
        }

        return (
          <li
            key={index}
            className={`px-3 py-1 rounded cursor-pointer ${pageNumber === currentPage ? 'bg-primary text-white' : 'text-primary hover:bg-gray-light'}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/* Right Navigation arrow */}
      <li
        className={`px-3 py-1 rounded cursor-pointer ${currentPage === lastPage ? 'text-gray-light pointer-events-none' : 'text-muted hover:bg-gray-light'}`}
        onClick={handleNext}
      >
        &rarr;
      </li>
    </ul>
  );
};

export default Pagination;

import { Container, Grid } from "@mui/material";
import Pagination from "../../../components/pagination/Pagination";
import FilterSidebar from "../../../components/product-filter/FilterSidebar";
import ProductCard from "../../../components/product-card/ProductCard";
import { useEffect, useState } from "react";
import { getCatalogList } from "../../../api/lib/product";
import { useAuth } from "../../../context/AuthContext";
import ProductListShimmer from "../../../components/shimmer-ui-components/ProductListShimmer";
import { useSearchParams } from "react-router-dom";
import SortingBar from "../../../components/product-filter/SortingBar";

const ProductCardContainer = () => {
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("q") || ""; // Fetch the `q` parameter
  const brandId = searchParams.get("brand") || "";
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState([]);
  const { currentUser, token } = useAuth();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await getCatalogList({
          page: pageNumber,
          limit: pageLimit,
          categoryId: categoryId || null,
          brandCodes: brandId || null
        });
        setCatalog(response.data.data.list);
        setTotalPages(response.data.data.totalPages);
      } catch (error) {
        console.error("Error Fetching Data", error);
      } finally {
        setLoading(false);
        window.scrollTo(0, 0);
      }
    };
    fetchProducts();

  }, [token, pageNumber, pageLimit, categoryId]);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  return (
    <Container maxWidth={"xl"} sx={{ marginTop: 5 }}>
      <div className="flex justify-start gap-3">
        <div id="filter-sidebar" className="w-96">
          <FilterSidebar />
        </div>
        <div className="w-full">
          <div className="flex flex-col w-full">
            <div id="sort-bar" className="mb-3 ">
              <SortingBar />
            </div>
            <div className="w-full">
              <div className="flex flex-col w-full">
                {/* <div id="sort-bar" className="mb-3">
                  <SortingBar />
                </div> */}
                <Grid container columns={20} className="flex justify-start items-stretch gap-[1rem]">
                  {catalog?.map((product, index) => (
                    <ProductCard key={index} product={product} />
                  ))}
                </Grid>
                <div id="pagination-ribbon" className="" >

                  <Pagination
                    currentPage={pageNumber}
                    totalPageCount={totalPages}
                    pageLimit={pageLimit}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Container>
  );
};

export default ProductCardContainer;

import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import "./global.css";
import Footer from "./pages/footer/Footer";
import Header from "./pages/header/Header";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { WishlistContextProvider } from "./context/WishlistContext";
import { CartContextProvider } from "./context/CartContext";
import { AuthProvider } from "./context/AuthContext";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-blue/theme.css";
import {
  browserName,
  deviceType,
  osVersion,
  osName,
} from "react-device-detect";

function App() {
  useEffect(() => {
    console.log(browserName, deviceType, osName, osVersion);
    if (browserName) {
      document.body.classList.add(browserName.replace(/ /g,''));
    }
    if (deviceType) {
      document.body.classList.add(deviceType.replace(/ /g,''));
    }
    if (osName) {
      document.body.classList.add(osName.replace(/ /g,''));
    }
    if (osVersion) {
      document.body.classList.add(osVersion.replace(/ /g,''));
    }

    
    // Cleanup function to remove classes when component unmounts
    return () => {
      document.body.classList.remove(
        browserName.replace(/ /g,''),
        deviceType.replace(/ /g,''),
        osName.replace(/ /g,''),
        osVersion.replace(/ /g,'')
      );
    };
  }, []);

  return (
    <>
      <PrimeReactProvider>
        <AuthProvider>
          <CartContextProvider>
            <WishlistContextProvider>
              <Header />
              <Suspense fallback={<>Loading....</>}>
                <Outlet />
              </Suspense>
              <Footer />
            </WishlistContextProvider>
          </CartContextProvider>
        </AuthProvider>
      </PrimeReactProvider>
    </>
  );
}

export default App;

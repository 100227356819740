import React from 'react'

const ReturnPolicy = () => {
  return (
    <div className='returnpolicy-wrapper'>
      <p className='lg:mb-8 mb-4 text-2xl color-lightblack'><strong>Return Policy</strong></p>
      <p className='pt-4'><strong>Terms for Return and Exchange</strong></p>
      <p>You may initiate the request for return or exchange of the product/item subject to adherence of the below mentioned timelines:</p>
      <p>(i) Within 7 (seven) days from the date of door step delivery to you (including date of delivery) for orders 
        placed through LOTS Ecommerce Platform.
      </p>
      <p>(ii) Within 7 (seven) days from the date issue of invoice for items purchased through any of the LOTS’ Store.</p>

      <p className='pt-4'>
        a) Products/ Items exempted from Return/ Exchange
        <br/>
        Due to the nature of our business, several types of products/items are exempt from being returned/exchanged. These items include –
      </p>
      <p>(i) Fresh food (e.g. fruits, vegetables, meat, eggs etc.)</p>
      <p>(ii) Dairy (e.g. Milk, Curd, Cheese, Paneer, Butter etc.) </p>
      <p>(iii) Ghee</p>
      <p>(iv) Frozen Food items (e.g. McCain frozen foods, ice-cream, frozen vegetables, frozen non-vegetarian items etc.)</p>
      <p>(v) Perishable items</p>
      <p>(vi) Products/Items used for Personal Hygiene</p>
      <p>(vii) Bakery items</p>
      <p>(viii) Loose/open food items</p>
      <p>Please note that the above list is subject to change with prior notice and for the updated information of the eligibility for 
        return or exchange of a particular item, you may reach out to us at contactus@cpwi.in.
      </p>

      <p className='pt-4'>
        b) Conditions for accepting Return and Exchange
        <br/>
        You shall be solely responsible to ensure that the products/ items are thoroughly checked by you (including but not limited to quantity, 
        shelf life, quality of items) at the time of purchase and/or delivery. Once these items have been delivered and accepted by you, you cannot 
        claim return/exchange for any item on the basis of short quantity, limited balance shelf life, damage to product/its packaging etc. and 
        the same shall be treated as deemed accepted by you.
      </p>
    </div>
  )
}

export default ReturnPolicy

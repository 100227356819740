import { Box, Divider, Grid, Typography, Button } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext';



const EditProfile = () => {
    const { currentUser } = useAuth();
    const [firstName, setFirstName] = useState(currentUser?.fullName.split(' ')[0] || "");
    const [lastName, setLastName] = useState(currentUser?.fullName.split(' ')[1] || "");
    const [gender, setGender] = useState(currentUser?.gender || "");
    const [dob, setDob] = useState(currentUser?.dob || "");
    const [phoneNumber, setPhoneNumber] = useState(currentUser?.phone || "");
    const [phoneNumberErr, setPhoneNumberErr] = useState('');
    const [address, setAddress] = useState(currentUser?.address || "");

    const handleSave = () => {
        // Handle save logic here
    };

    return (
        <>
            <Grid item xs={6} >
                <Box marginBottom={2}>
                    <div className="uppercase text-sm text-gray-dark">Edit Profile</div>
                </Box>
                <div className="pl-4 pt-3 pb-5 border-2 border-gray">
                    <div className="text-sm">Email Id</div>
                    <div className="bg-gray-light h-9 px-2 py-[4px] items-center w-[400px] mt-1 rounded-sm">
                        {currentUser?.email}
                    </div>
                </div>
                <Box marginTop={3} paddingBottom={5} border="1px solid black">
                    <Typography variant="body2" sx={{ marginTop: 3, marginBottom: 3, marginLeft: 2.5 }}>
                        General Information
                    </Typography>

                    <Divider />
                    <Grid display="flex" justifyContent="space-around" container>
                        <Grid display="flex" item flexDirection="column" justifyContent="space-between" xs={5.3}>
                            <div className="border-2 my-2 outline-none">
                                <input type="text" name="firstname" id="firstName" placeholder="First Name*" className="h-full w-full pl-2 py-2 bg-gray-light" value={firstName} readOnly />
                            </div>
                            <div className="border-2 my-2 outline-none">
                                <input type="text" name="lastname" id="lastName" placeholder="Last Name*" className="h-full w-full pl-2 py-2 bg-gray-light" value={lastName} readOnly />
                            </div>
                            <div className="my-2">
                                <div className="text-sm">Gender</div>
                                <div className="flex justify-start items-center outline-none">
                                    <div className="flex items-center mr-3">
                                        <input type="radio" name="gender-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light accent-primary" value="Male" checked={gender === "Male"} onChange={(e) => setGender(e.target.value)} />
                                        <label className="ms-2 text-sm font-medium text-gray-dark">Male</label>
                                    </div>
                                    <div className="flex items-center mr-3">
                                        <input type="radio" name="gender-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light accent-primary" value="Female" checked={gender === "Female"} onChange={(e) => setGender(e.target.value)} />
                                        <label className="ms-2 text-sm font-medium text-gray-dark">Female</label>
                                    </div>
                                    <div className="flex items-center mr-3">
                                        <input type="radio" name="gender-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light accent-primary" value="Others" checked={gender === "Others"} onChange={(e) => setGender(e.target.value)} />
                                        <label className="ms-2 text-sm font-medium text-gray-dark">Others</label>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Grid display="flex" flexDirection="column" justifyContent="space-between" item xs={5.3}>
                            <div className="border-2 px-2 py-2 my-2 flex">
                                <input type="date" name="birthdate" id="birthdate" className="w-full outline-none" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </div>
                            <div className={`border-2 my-2 outline-none`}>
                                <input type="text" name="phone" id="phoneNumber" placeholder="Phone Number*" className="w-full h-full pl-2 py-2" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            </div>
                            {phoneNumberErr && <small className="text-sm text-red">{phoneNumberErr}</small>}
                            <div className="border-2 pl-2 py-2 my-2">
                                <input type="text" name="address" id="address" placeholder="Address*" className="w-full outline-none" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                        </Grid>
                        <Divider />
                        <Box display="flex" justifyContent="center" marginTop="20px">
                            <Button disableRipple variant="contained" color="primary" onClick={handleSave} style={{ backgroundColor: "rgb(16,123,122)", width: "200px" }}>
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}

export default EditProfile
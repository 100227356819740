import React, { useEffect, useRef } from 'react';

const PaypalCheckout = () => {
    const paypal = useRef();

    useEffect(() => {
        const loadPaypalScript = () => {
            const script = document.createElement('script');
            script.src = "https://www.paypal.com/sdk/js?client-id=sb-yhwvi32125621";
            script.onload = () => {
                window.paypal.Buttons({
                    createOrder: (data, actions, err) => {
                        return actions.order.create({
                            intent: "CAPTURE",
                            purchaseUnits: [
                                {
                                    description: "Cool Looking Shirt",
                                    amount: {
                                        currency_code: "USD",
                                        value: 999
                                    }
                                }
                            ]
                        });
                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        console.log("Successful Order", order);
                    },
                    onError: (err) => {
                        console.error(err);
                    }
                }).render(paypal.current);
            };
            document.body.appendChild(script);
        };

        if (!window.paypal) {
            loadPaypalScript();
        } else {
            window.paypal.Buttons({
                createOrder: (data, actions, err) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchaseUnits: [
                            {
                                description: "Cool Looking Shirt",
                                amount: {
                                    currency_code: "USD",
                                    value: 999
                                }
                            }
                        ]
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log("Successful Order", order);
                },
                onError: (err) => {
                    console.error(err);
                }
            }).render(paypal.current);
        }
    }, []);

    return <div ref={paypal}></div>;
};

export default PaypalCheckout;

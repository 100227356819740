import React from 'react'

interface DropdownItemProps {
    availableOptions: any;
    handleOptionClick: (option)=>void;
  }

const DropdownItems: React.FC<DropdownItemProps>  = ({availableOptions,handleOptionClick}) => {
  return (
    <div className="origin-top-right absolute right-0 mt-10 w-full rounded-sm  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="availableOptions-menu">
                      <div className="py-1" role="none">
                        {availableOptions.map(option => (
                          <button
                            key={option}
                            className="block px-4 py-2 text-sm text-gray-700 font-bold hover:bg-gray-light w-full text-left"
                            role="menuitem"
                            onClick={() => handleOptionClick(option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
  )
}

export default DropdownItems
import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import SignIn from './SignInPopup';
import { ConfirmationResult } from 'firebase/auth';
import SignInPopup from './SignInPopup';
import ValidateOTPPopup from './ValidateOTPPopup';
import SignUpPopup from './SignUpPopup';

interface PopupOutletProps {
    visible: boolean;
    onHide: () => void;
    setVisibleLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupOutlet: React.FC<PopupOutletProps> = ({ visible, onHide, setVisibleLogin }) => {
    const stepperRef = useRef<any>(null);
    const [steps, setSteps] = useState(1);
    const [confirmationResult, setConfirmationResult]= useState<ConfirmationResult | null>(); 
    const [firebaseMetadata,setFirebaseMetadata]= useState()
    return (
        <Dialog draggable={false} visible={visible} style={{ width: '35vw' }} onHide={onHide} className="bg-white p-popup">
            <div className="mainWrapper">
                {steps === 1 && (
                    <div className="firststep">
                        <div className="inner-wrap">
                            <SignInPopup setSteps={setSteps} setVisibleLogin={setVisibleLogin}  setConfirmationResult={setConfirmationResult}/>
                        </div>
                    </div>
                )}

                {steps === 2 && (
                    <div className="otpscreen">
                         <ValidateOTPPopup setSteps={setSteps} setVisibleLogin={setVisibleLogin} confirmationResult={confirmationResult} setFirebaseMetadata={setFirebaseMetadata} />
                    </div>
                )}

                {steps === 3 && (
                    <div className="detailwrapper">
                        {/* <SignUpComponent /> */}
                        {/* SignUp Component */}
                        <SignUpPopup setSteps={setSteps} setVisibleLogin={setVisibleLogin} firebaseMetadata={firebaseMetadata}/>
                    </div>
                )}

                {steps === 4 && (
                    <div className="messagewrapper">
                        <svg
                            className='m-auto'
                            xmlns="http://www.w3.org/2000/svg"
                            width="54"
                            height="54"
                            fill="none"
                            viewBox="0 0 54 54"
                        >
                            <circle cx="27" cy="27" r="27" fill="#3C881F"></circle>
                            <path
                            fill="#fff"
                            d="M40.35 17.65a2.22 2.22 0 0 0-3.141 0L23.074 31.786l-6.282-6.283a2.22 2.22 0 1 0-3.141 3.142l7.852 7.852c.434.434 1.002.65 1.57.65s1.138-.216 1.571-.65L40.35 20.792a2.22 2.22 0 0 0 0-3.142"
                            ></path>
                        </svg>
                        <p className='text-center color-text-green text-2xl font-semibold mt-2'>Successfully Logged in!</p>
                    </div>
                )}


            {/* <Stepper ref={stepperRef} >
                    <StepperPanel >
                        <div className="flex flex-column h-12rem">
                            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"><SignInPopup /> </div>
                        </div>
                        <div className="flex pt-4 justify-content-end">
                            <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                    </StepperPanel>
                    <StepperPanel >
                        <div className="flex flex-column h-12rem">
                            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"><ValidateOTPPopup /></div>
                        </div>
                        <div className="flex pt-4 justify-content-between">
                            <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                            <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                    </StepperPanel>
                    <StepperPanel >
                        <div className="flex flex-column h-12rem">
                            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Login Successful</div>
                        </div>
                        <div className="flex pt-4 justify-content-start">
                            <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                        </div>
                    </StepperPanel>
                </Stepper> */}
        </div>
        </Dialog >
    );
};

export default PopupOutlet;

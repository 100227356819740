import axios from 'axios';
const host = process.env.REACT_APP_API_HOST;
const port = process.env.REACT_APP_SERVER_PORT;
const api_endpoint = process.env.REACT_APP_API_END_POINT;
const node_env = process.env.NODE_ENV;
console.log('node_env', node_env);
// const baseURL = 'https://api.pushkarfoods.com'
// const baseURL = node_env === 'production' ? `http://${host}:${port}` : api_endpoint;
// console.log('baseURL', baseURL);
// const baseURL= `http://${host}:${port}`; 
const axiosClient = axios.create({
    baseURL: api_endpoint,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`; // Corrected
        } else {
            config.headers["Authorization"] = process.env.REACT_APP_BASIC_TOKEN;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) { // Ensure error.response exists
            console.log('Unauthorized');
        }

        return Promise.reject(error);
    }
);

export default axiosClient;

import { useState } from 'react';
import { Icon } from '@iconify/react';

const Accordion = ({ title, children, defaultOpen = true }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const toggleAccordion = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="accordion-container">
            <button
                type="button"
                onClick={toggleAccordion}
                className="flex items-center justify-start w-full p-2 font-medium text-gray rounded-none gap-3"
                aria-expanded={isOpen}
            >
                <Icon
                    icon="iconamoon:arrow-down-2-duotone"
                    className={`transition-transform transform ${isOpen ? 'rotate-0' : '-rotate-90'}`}
                    width="16" // Adjust width and height as per your need
                    height="16"
                />
                <span className='text-md text-black font-semibold'>{title}</span>
            </button>
            <div
                className={`${isOpen ? 'block' : 'hidden'} p-2`}
                aria-labelledby={`accordion-${title.toLowerCase().replace(/\s+/g, '-')}`}
            >
                {children}
            </div>
        </div>
    );
};

export default Accordion;
